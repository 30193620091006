import { useMemo } from 'react';
import Link from 'next/link';

import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { useApplicationContext } from '@application';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { useHeaderSectionContext } from '../context';

export const useInfoData = () => {
  const {
    state: {
      bestPlayer: { data: player, status: bestPlayerStatus },
      stats: { data: stats, status: statsStatus },
    },
  } = useHeaderSectionContext();

  const { localization: l } = useApplicationContext();

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return (
      <Link
        href={link}
        onClick={() => gtmPush({ event: GTM_EVENTS.TOP_PROFILE_CLICK })}
      >
        {children}
      </Link>
    );
  };
  const infoData = useMemo(
    () => ({
      title: l?.main_page?.stats.top_rated_now,
      player,
      stats,
      renderLink,
      isLoading: statsStatus !== 'done' || bestPlayerStatus !== 'done',
    }),
    [
      bestPlayerStatus,
      l?.main_page?.stats.top_rated_now,
      player,
      stats,
      statsStatus,
    ]
  );

  return infoData;
};
