import { useMemo } from 'react';

import { usePuzzlesSectionContext } from './context';
import { getPuzzlesListRating } from './utils/_getPuzzlesListRating';
import { useHomeContext } from '@pages/home/context';

export const usePuzzlesDataRating = (withLink?: boolean) => {
  const {
    state: {
      data: { rating },
    },
  } = usePuzzlesSectionContext();
  const { data } = useHomeContext();

  return useMemo(
    () => getPuzzlesListRating(rating, withLink, data),
    [rating, data, withLink]
  );
};
