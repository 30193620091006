import { useMemo } from 'react';
import Link from 'next/link';

import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { useUserStatus } from '@utils/hooks/_useUserSatatus';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';

import { useHomeContext } from '../../context';
import { useHomeTournamentsContext } from './context';
import { getFeaturedTournaments } from './helpers/_getFeaturedTournaments';
import { getScheduledTournaments } from './helpers/_getScheduledTournaments';
import { useFeaturedTournamentsAction } from './_useFeaturedTournamentsAction.hook';

export const useTournamentsData = () => {
  const { localization: l } = useApplicationContext();
  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  const { data } = useHomeContext();

  const homeTournamentsData = data?.sections.tournaments;

  const { data: sectionData, loading } = useHomeTournamentsContext();

  const { disabledItems, onActionClick, leaveConfirmation, alert } =
    useFeaturedTournamentsAction();

  const title = useMemo(
    () => homeTournamentsData?.title || '',
    [homeTournamentsData]
  );

  const cardData = useMemo(
    () => ({
      description: homeTournamentsData?.description || '',
      viewAll: {
        text: homeTournamentsData?.view_all || '',
        href: routesConstants.TOURNAMENTS,
      },
      helpItems: homeTournamentsData?.help || [],
    }),
    [homeTournamentsData]
  );

  const tournaments = useMemo(
    () =>
      getFeaturedTournaments(
        sectionData?.featured || [],
        sectionData?.my || [],
        userStatus,
        fideIdStatus,
        disabledItems,
        l
      ),
    [
      disabledItems,
      fideIdStatus,
      l,
      sectionData?.featured,
      sectionData?.my,
      userStatus,
    ]
  );

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return <Link href={link}>{children}</Link>;
  };

  const listData = {
    title: homeTournamentsData?.next || '',
    link: routesConstants.TOURNAMENTS,
    items: getScheduledTournaments(
      sectionData?.scheduled || [],
      sectionData?.my || [],
      l
    ),
    renderLink,
  };

  return {
    title,
    cardData,
    tournaments,
    listData,
    renderLink,
    onActionClick,
    isLoading: loading,
    leaveConfirmation,
    alert,
  };
};
