import { FC, memo } from 'react';

import { HeaderSectionContextProvider } from './context';
import { HomeHeaderFragment } from './fragments/HomeHeaderFragment';

export const HeaderSection: FC = memo(function HeaderSection() {
  return (
    <HeaderSectionContextProvider>
      <HomeHeaderFragment />
    </HeaderSectionContextProvider>
  );
});
