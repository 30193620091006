import { IHomePageData } from '@pages/home/_types';
import { BoardType } from 'shared/lib/types';

export const getTimeControlTimeInit = (
  data?: IHomePageData,
  type?: string
): string => {
  if (!data || !type) return '';
  const times = data.time_controls[type as unknown as BoardType];
  return String(times[0]?.id);
};
