import { useCallback, useEffect, useState } from 'react';

export interface UseMountedParams {
  onMount?: () => void;
  onUnmount?: () => void;
}

export const useMounted = ({ onMount, onUnmount }: UseMountedParams = {}) => {
  const [mounted, setMounted] = useState(false);

  const mount = useCallback(() => {
    setMounted(true);
    if (!!onMount) onMount();
  }, [onMount]);

  const unmount = useCallback(() => {
    setMounted(false);
    if (onUnmount) onUnmount();
  }, [onUnmount]);

  useEffect(() => {
    if (!mounted) mount();
    return () => {
      if (mounted) unmount();
    };
  }, [mounted, mount, unmount]);

  return mounted;
};
