import {
  IQuickGameTab,
  IQuickGameTabItem,
} from '@pages/home/sections/QuickGameSection/_types';
import { IHomePageData } from '@pages/home/_types';
import { ITimeControl } from 'shared/types';

export const getPreparedTabs = (data?: IHomePageData): IQuickGameTab[] => {
  const items = data?.sections.quick_game.tabs || [];
  if (!data?.time_controls) return [];

  const timeControls = Object.keys(data.time_controls).reduce<ITimeControl[]>(
    (acc, key) => {
      acc.push(...data.time_controls[key]);
      return acc;
    },
    []
  );

  return items.map((item) => ({
    ...item,
    items:
      (item.items
        ?.map((element) => {
          const timeControl = timeControls.find((item) => item.id === element);

          if (!timeControl) return null;

          return {
            id: timeControl?.id.toString(),
            timeControl: timeControl,
          };
        })
        .filter(Boolean) as IQuickGameTabItem[]) || [],
  }));
};
