import { HomePlayersItem } from '@chessarena/components/lib/home/HomePlayers/types';
import { IGameInQueueData } from '@store/context/lobby_context/_lobby_context.types';
import { eAccessLevel } from 'shared/types';

const defaultImage =
  'https://gaming-images.worldchess.com/media/default-user-avatar.svg';

const MAXIMUM_GAMES_SHOWN_IN_QUEUE = 5;

const adaptGameInQueue = (player: IGameInQueueData) => {
  const [lastName, firstName] = player.owner?.full_name?.split(', ') ?? [
    '',
    '',
  ];

  return {
    id: player.owner?.uid ?? player.ownerAnonymousUid ?? '',
    avatar: {
      src: player.owner?.avatar?.small || defaultImage,
      premium: player.owner?.access_level === eAccessLevel.PRO,
    },
    firstname: firstName ?? '',
    lastname: lastName ?? '',
  };
};

const getUniquePlayers = (gamesInQueue: IGameInQueueData[]) => {
  const uniqueGameUidsSet = new Set<string>([]);
  const uniqueGamesInQueue: IGameInQueueData[] = [];

  gamesInQueue.forEach((player) => {
    const playerUid = player.owner?.uid || player.ownerAnonymousUid || '';

    if (playerUid) {
      if (!uniqueGameUidsSet.has(playerUid)) {
        uniqueGameUidsSet.add(playerUid);
        uniqueGamesInQueue.push(player);
      }
    }
  });

  return uniqueGamesInQueue;
};

export const adaptGamesInQueue = (
  gamesInQueue: IGameInQueueData[] | null
): HomePlayersItem[] => {
  if (!gamesInQueue) return [];

  const uniqueGames = getUniquePlayers(gamesInQueue);
  const adaptedGamesInQueue = uniqueGames
    .slice(0, MAXIMUM_GAMES_SHOWN_IN_QUEUE)
    .map(adaptGameInQueue);

  return adaptedGamesInQueue;
};
