import {
  eQuickGameSectionReducerActionTypes,
  IQuickGameSectionReducerAction,
  IQuickGameSectionContextState,
} from './_types';
import { getPreparedTabs } from '../../utils/_getPreparedTabs';
import { IHomePageData } from '@pages/home/_types';

const initStateData = {
  mounted: false,
  activeTabId: null,
  gameIsStarting: false,
  inviteSettingsOpened: false,
};

export const createInitState = (
  data?: IHomePageData
): IQuickGameSectionContextState => ({
  status: 'request',
  error: null,
  data: {
    mounted: initStateData.mounted,
    activeTabId: initStateData.activeTabId,
    tabs: getPreparedTabs(data),
    gameIsStarting: initStateData.gameIsStarting,
    inviteSettingsOpened: initStateData.inviteSettingsOpened,
  },
});

export const QuickGameSectionReducer = (
  prevState: IQuickGameSectionContextState,
  { type, payload }: IQuickGameSectionReducerAction
): IQuickGameSectionContextState => {
  switch (type) {
    case eQuickGameSectionReducerActionTypes.SET_MOUNTED:
      return {
        ...prevState,
        status: 'done',
        data: {
          ...prevState.data,
          mounted: payload,
        },
      };
    case eQuickGameSectionReducerActionTypes.SET_TABS:
      return {
        ...prevState,
        status: 'done',
        data: {
          ...prevState.data,
          tabs: payload,
        },
      };
    case eQuickGameSectionReducerActionTypes.SET_ACTIVE_TAB_ID:
      return {
        ...prevState,
        status: 'done',
        data: {
          ...prevState.data,
          activeTabId: payload,
        },
      };
    case eQuickGameSectionReducerActionTypes.SET_GAME_IS_STARTING:
      return {
        ...prevState,
        data: {
          ...prevState.data,
          gameIsStarting: payload,
        },
      };
    case eQuickGameSectionReducerActionTypes.SET_INVITE_SETTINGS_OPENED:
      return {
        ...prevState,
        data: {
          ...prevState.data,
          inviteSettingsOpened: payload,
        },
      };
    case eQuickGameSectionReducerActionTypes.SET_LOADING:
      return {
        ...prevState,
        status: payload ? 'request' : 'done',
      };
    case eQuickGameSectionReducerActionTypes.SET_ERROR:
      return {
        ...prevState,
        status: 'error',
        error: payload,
      };
    default:
      throw new Error('undefined type of action');
  }
};
