import { LogoJsonLd, NextSeo } from 'next-seo';
import { memo } from 'react';

import { HomeBotSelectionSection } from '@chessarena/components/lib/home/HomeBotSelectionSection';

import { useApplicationContext } from '@application';
import AppLayout from '@layouts/app';
import { eStoredId, storedSsgService } from '@services/_stored.service';
import { NextPageWithLayout } from '@types';

import { BotSelectionSection } from '@modules/BotSelectionSection/BotSelectionSection';

import { IHomePageProps } from './home/_types';
import { HomeContextProvider } from './home/context';
import { getPageLogoJsonLd, getPageSeo } from './home/helpers/_getPageSeo';
import { useHomePageData } from './home/hooks/useHomePageData.hook';
import { HeaderSection } from './home/sections/HeaderSection/HeaderSection';
import { HomePageBottomSection } from './home/sections/HomePageBottomSection/HomePageBottomSection';
import { MasterclassSection } from './home/sections/MasterclassSection/MasterclassSection';
import { PuzzlesSection } from './home/sections/PuzzlesSection';
import { QuickGameSection } from './home/sections/QuickGameSection';
import { TournamentsSection } from './home/sections/TournamentsSection';
import { WelcomeSection } from './home/sections/WelcomeSection/WelcomeSection';

const HomePage: NextPageWithLayout<IHomePageProps> = memo(function HomePage(
  props
) {
  const { data } = props;
  const { locale } = useApplicationContext();
  const seo = getPageSeo(locale);
  const logoJsonLd = getPageLogoJsonLd();

  useHomePageData();

  return (
    <>
      <NextSeo {...seo} />
      <LogoJsonLd {...logoJsonLd} />
      <HomeContextProvider data={data}>
        <HeaderSection />
        <QuickGameSection />
        <HomeBotSelectionSection>
          <BotSelectionSection
            data={data?.sections.bot_selection}
            timeControls={data?.time_controls}
            gameSettingsTimeControlOrder={
              data?.game_settings_time_control_order
            }
          />
        </HomeBotSelectionSection>
        <TournamentsSection />
        <MasterclassSection />
        <PuzzlesSection />
        <WelcomeSection />
        <HomePageBottomSection />
      </HomeContextProvider>
    </>
  );
});

HomePage.getLayout = (page) => {
  return <AppLayout>{page}</AppLayout>;
};

export const getStaticProps = async () => {
  try {
    const { data } = await storedSsgService.getData(eStoredId.PAGE_HOME);
    return { props: { data: data?.data }, revalidate: 86400 };
  } catch (error) {
    console.log(`~error~`, error);
    return { props: {}, revalidate: 86400 };
  }
};

export default HomePage;
