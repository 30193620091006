import { FC, memo } from 'react';
import { BottomSectionContextProvider } from './context';
import { HomePageBottomFragment } from './fragments/HomePageBottomFragment';

export const HomePageBottomSection: FC = memo(function HomePageBottomSection() {
  return (
    <BottomSectionContextProvider>
      <HomePageBottomFragment />
    </BottomSectionContextProvider>
  );
});
