"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$HomeTimeControlsMessageInner = exports.$HomeTimeControlsMessage = exports.$HomeTimeControlsListChild = exports.$HomeTimeControlsSkeleton = exports.$HomeTimeControlsList = exports.$HomeTimeControls = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const constants_1 = require("../../common/constants");
function getTheme(theme) {
    return {
        message: {
            backgroundColor: theme.semantic_color.bg_additional.bllur_on_bg_page,
        },
    };
}
const animation = (0, styled_components_1.keyframes) `from { opacity: 0.6; } 40% { opacity: 0.2} to { opacity: 0.6; }`;
exports.$HomeTimeControls = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
`;
exports.$HomeTimeControlsList = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (${constants_1.media.tablet}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
exports.$HomeTimeControlsSkeleton = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  height: 96px;
  background: ${(props) => props.theme.semantic_color.bg_primary.first_layer};
  animation: 1.4s ease-in-out infinite ${animation};

  @media (${constants_1.media.tablet}) {
    height: 100px;
  }

  @media (${constants_1.media.desktop}) {
    height: 106px;
  }
`;
exports.$HomeTimeControlsListChild = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-end;
  grid-column: 2 / 4;

  @media (${constants_1.media.tablet}) {
    display: none;
  }
`;
exports.$HomeTimeControlsMessage = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background-color: ${(props) => getTheme(props.theme).message.backgroundColor};
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  padding: 16px;
  border-radius: 4px;
  z-index: 1;

  @media (${constants_1.media.desktop}) {
    padding: 1px;
  }
`;
exports.$HomeTimeControlsMessageInner = styled_components_1.default.div `
  height: 100%;
`;
