import { useMemo } from 'react';

import { useApplicationContext } from '@application';

import { useHeaderSectionContext } from '../context';

export const useEventData = () => {
  const {
    state: { eventData: contextEventData },
  } = useHeaderSectionContext();

  const { localization: l } = useApplicationContext();

  const eventData = useMemo(() => {
    return {
      imageSrc: contextEventData?.eventImage ?? '',
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: contextEventData?.eventText || '',
          }}
        />
      ),
    };
  }, [contextEventData?.eventImage, l?.main_page?.month_update.text]);

  if (!contextEventData?.eventImage && !contextEventData?.eventText)
    return undefined;

  return eventData;
};
