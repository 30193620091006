import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { getCategories } from '../fetchData/_getCategories';
import { getRating } from '../fetchData/_getRating';
import { useActions } from './_actions';
import { PuzzlesSectionContext } from './_context';
import { createInitState, puzzlesSectionReducer } from './_reducer';
import { IPuzzlesSectionContextProps } from './_types';

export const PuzzlesSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    puzzlesSectionReducer,
    createInitState()
  );

  const {
    setLoading: setLoadingAction,
    setError: setErrorAction,
    setData: setDataAction,
  } = useActions(dispatch);

  const getData = useCallback(async () => {
    try {
      setLoadingAction(true);
      const params = { limit: 9 };
      const [categories, rating] = await Promise.all([
        getCategories(params),
        getRating(),
      ]);

      setDataAction({ categories, rating });
    } catch (error) {
      console.log(error);
      setErrorAction('get puzzles section data error');
    } finally {
      setLoadingAction(false);
    }
  }, [setLoadingAction, setDataAction, setErrorAction]);

  useEffect(() => {
    getData();
  }, []);

  const value = useMemo<IPuzzlesSectionContextProps>(() => {
    return {
      state,
      actions: {},
    };
  }, [state]);

  return (
    <PuzzlesSectionContext.Provider value={value}>
      {children}
    </PuzzlesSectionContext.Provider>
  );
};
