import { useCallback, useMemo } from 'react';
import Link from 'next/link';

import { ButtonType } from '@chessarena/components/lib/general/Button/types';
import {
  HomeMessageIconTypes,
  HomeMessageTypes,
} from '@chessarena/components/lib/home/HomeMessage/types';

import { useHomeContext } from '@pages/home/context';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { eAccessLevel, GameRatingMode } from 'shared/types';
import { eFideIdStatus } from '@types';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { useQuickGameSectionContext } from './contexts/quick-game';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { SpecialButtonClickEventParams } from '@chessarena/components/lib/layout/SpecialButton/types';
import { openPopup } from '@utils/_router';
import { ePopupPaths } from '@constants';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';

export const useQuickGameMessageData = () => {
  const {
    state: {
      data: { activeTabId: activeTab },
    },
  } = useQuickGameSectionContext();

  const { data } = useHomeContext();

  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const fideIdStatus = useFideIdStatus();
  const accessLevel = userData?.access_level;

  const message = useMemo(() => {
    if (!activeTab || activeTab === GameRatingMode.UNRATED) return null;

    const m = data?.sections.quick_game.messages;

    if (!logged)
      return {
        type: 'primary' as HomeMessageTypes,
        title: m?.register.title || '',
        icon: 'king' as HomeMessageIconTypes,
        description: m?.register.description || '',
        readMore: {
          text: m?.register.link_text || '',
          href: m?.register.link_href || '/',
        },
        action: {
          id: 'register',
          type: 'primary' as ButtonType,
          label: m?.register.action || '',
        },
      };

    if (activeTab === GameRatingMode.RATED) return null;

    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
        return {
          type: 'capri' as HomeMessageTypes,
          title: m?.fide_id.title || '',
          icon: 'fide' as HomeMessageIconTypes,
          description: m?.fide_id.description || '',
          readMore: {
            text: m?.fide_id.link_text || '',
            href: m?.fide_id.link_href || '/',
          },
          action: {
            id: 'fideId',
            type: 'capri' as ButtonType,
            label: m?.fide_id.action || '',
          },
        };
      return null;
    }

    return {
      type: 'yellow' as HomeMessageTypes,
      title: m?.upgrade.title || '',
      icon: 'upgrade' as HomeMessageIconTypes,
      description: m?.upgrade.description || '',
      readMore: {
        text: m?.upgrade.link_text || '',
        href: m?.upgrade.link_href || '/',
      },
      action: {
        id: 'upgrade',
        type: 'yellow' as ButtonType,
        label: m?.upgrade.action || '',
      },
    };
  }, [activeTab, data, logged, accessLevel, fideIdStatus]);

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return <Link href={link}>{children}</Link>;
  };

  const onFideIdClick = useCallback(() => {
    if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
      openPopup(`?popup=${ePopupPaths.FIDE}`);
  }, [fideIdStatus]);

  const onClick = ({ id }: SpecialButtonClickEventParams) => {
    switch (id) {
      case 'register':
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
        break;
      case 'fideId':
        onFideIdClick();
        break;
      case 'upgrade':
        openPopup(
          `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.GAMES}`
        );
        break;
      default:
        break;
    }
  };

  if (!message) return null;

  return {
    ...message,
    renderLink,
    onClick,
  };
};
