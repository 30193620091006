import { useCallback, useMemo, useState } from 'react';

import { TournamentCardActionClickParams } from '@chessarena/components/lib/tournaments/TournamentCard/types';
import formatLabel from '@chessarena/components/lib/utils/formatLabel';

import { useApplicationContext } from '@application';
import { tournamentService } from '@services/_tournament.service';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { useHomeTournamentsContext } from './context';

export const useFeaturedTournamentsLeave = () => {
  const [leaveConfirmationId, setLeaveConfirmationId] = useState<number>();
  const [leaveConfirmationOpen, setLeaveConfirmationOpen] = useState(false);

  const { localization: l } = useApplicationContext();

  const { getData, data: sectionData } = useHomeTournamentsContext();

  const handleLeaveConfirmationConfirm = useCallback(async () => {
    const leaveTournament = sectionData?.featured.find(
      (item) => item.id === leaveConfirmationId
    );

    setLeaveConfirmationOpen(false);

    if (leaveTournament) {
      await tournamentService.signoutTournament(leaveTournament.id);
      gtmPush({ event: GTM_EVENTS.LEFT_TOURNAMENT });
      getData(true);
    }
  }, [sectionData?.featured, leaveConfirmationId, getData]);

  const handleLeaveConfirmationCancel = () => {
    setLeaveConfirmationOpen(false);
  };

  const leaveConfirmationCaptions = useMemo(() => {
    const leaveTournament = sectionData?.featured.find(
      (item) => item.id === leaveConfirmationId
    );

    return {
      labels: {
        confirm:
          l.tournaments_page?.my_upcoming_tournaments?.confirmation?.labels
            ?.confirm,
        cancel:
          l.tournaments_page?.my_upcoming_tournaments?.confirmation?.labels
            ?.cancel,
      },
      title: formatLabel(
        l.tournaments_page?.my_upcoming_tournaments?.confirmation?.title,
        { name: leaveTournament?.title }
      ),
      content:
        l.tournaments_page?.my_upcoming_tournaments?.confirmation?.content,
    };
  }, [sectionData?.featured, l.tournaments_page, leaveConfirmationId]);

  const onLeaveActionClick = useCallback(
    (params: TournamentCardActionClickParams) => {
      const { tournamentId } = params;
      setLeaveConfirmationId(parseInt(tournamentId));
      setLeaveConfirmationOpen(true);
      gtmPush({ event: GTM_EVENTS.LEAVE_TOURNAMENT_CLICK });
    },
    []
  );

  return {
    ...leaveConfirmationCaptions,
    onLeaveActionClick,
    open: leaveConfirmationOpen,
    confirm: handleLeaveConfirmationConfirm,
    cancel: handleLeaveConfirmationCancel,
  };
};
