import {
  eHomePageHeaderContextActionType,
  THomePageHeaderContextReducer,
} from './_types';

export const homePageHeaderContextReducer: THomePageHeaderContextReducer = (
  state,
  { type, payload }
) => {
  switch (type) {
    case eHomePageHeaderContextActionType.SET_EVENT_DATA_REQUEST:
      return {
        ...state,
        eventData: {
          ...state.eventData,
          ...payload,
        },
      };

    case eHomePageHeaderContextActionType.SET_BEST_PLAYER_REQUEST:
      return {
        ...state,
        bestPlayer: {
          ...state.bestPlayer,
          ...payload,
        },
      };

    case eHomePageHeaderContextActionType.SET_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          ...payload,
        },
      };
    default:
      return state;
  }

  return state;
};
