import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';
import { useUserDataStore } from '@store/storeshed';

dayjs.extend(isToday);

export const UNSUBSCRIBED_PUZZLES_LIMIT = 5;

export const LOCALE_STORAGE_KEYS = {
  UNSUBSCRIBED_PUZZLES_QUANITY: 'unsubscribedPuzzlesQuantity',
  UNSUBSCRIBED_PUZZLES_DATE: 'unsubscribedPuzzlesDate',
};

export const useUnsubscribedPuzzles = () => {
  const [solvedPuzzlesQuantity, setSolvedPuzzlesQuantity] = useState(0);

  const userData = useUserDataStore('data');
  const userStatus = useUserStatus();

  useEffect(() => {
    if (typeof window === undefined) return;

    if (userStatus === UserStatus.SUBSCRIBED) {
      setSolvedPuzzlesQuantity(0);
      return;
    }

    const unsubscribedPuzzlesDate = localStorage.getItem(
      LOCALE_STORAGE_KEYS.UNSUBSCRIBED_PUZZLES_DATE
    );

    const unsubscribedPuzzlesQuantity = localStorage.getItem(
      LOCALE_STORAGE_KEYS.UNSUBSCRIBED_PUZZLES_QUANITY
    );

    if (unsubscribedPuzzlesDate && dayjs(unsubscribedPuzzlesDate).isToday()) {
      setSolvedPuzzlesQuantity(Number(unsubscribedPuzzlesQuantity) || 0);
    }
  }, [userStatus]);

  const limitExhausted = solvedPuzzlesQuantity >= UNSUBSCRIBED_PUZZLES_LIMIT;

  const increase = useCallback(() => {
    if (userStatus === UserStatus.SUBSCRIBED) return;

    localStorage.setItem(
      LOCALE_STORAGE_KEYS.UNSUBSCRIBED_PUZZLES_DATE,
      dayjs().format()
    );

    localStorage.setItem(
      LOCALE_STORAGE_KEYS.UNSUBSCRIBED_PUZZLES_QUANITY,
      (solvedPuzzlesQuantity + 1).toString()
    );

    if (solvedPuzzlesQuantity + 1 >= UNSUBSCRIBED_PUZZLES_LIMIT) {
      gtmPush({
        event: GTM_EVENTS.LIMIT_PUZZLE,
        paramUserId: userData?.player.player_id,
      });
    }

    setSolvedPuzzlesQuantity(solvedPuzzlesQuantity + 1);
  }, [solvedPuzzlesQuantity, userData, userStatus]);

  return { solvedPuzzlesQuantity, limitExhausted, increase };
};
