import { HomeWelcomeProps } from '@chessarena/components/lib/home/HomeWelcome/types';
import { THomePageWelcomeStoredDataResponse } from '@pages/home/_types';

export const adaptWelcomeResponse = (
  data?: THomePageWelcomeStoredDataResponse
): HomeWelcomeProps | undefined => {
  if (!data) return;

  const items = data;

  return items;
};
