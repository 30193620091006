import { useMemo } from 'react';

import { routesConstants } from '@constants';
import { useHomeContext } from '@pages/home/context';

export const usePuzzlesDataEnjoy = (withLink?: boolean) => {
  const { data } = useHomeContext();

  return useMemo(() => {
    return {
      id: 'enjoy',
      link: withLink ? routesConstants.PUZZLES : undefined,
      title: data?.sections?.puzzles.items.enjoy.title || '',
      subTitle: data?.sections?.puzzles.items.enjoy.sub_title || '',
    };
  }, [data, withLink]);
};
