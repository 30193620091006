import { THomePageBottomStoredDataResponse } from '@pages/home/_types';
import { TBottomItems } from './_types';

export const adaptBottomItemsResponse = (
  data?: THomePageBottomStoredDataResponse
): TBottomItems => {
  if (!data || !data?.items) return [];

  const items = data.items;

  return items.map((item) => ({
    ...item,
  }));
};
