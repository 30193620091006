import { Dispatch, useCallback } from 'react';

import { ITourGameData } from 'shared/types';

import { eTopGameReducerActionTypes, ITopGameReducerAction } from './_types';

export const useActions = (dispatch: Dispatch<ITopGameReducerAction>) => {
  return {
    setTopRatedGame: useCallback(
      (value: ITourGameData | null): void => {
        return dispatch({
          type: eTopGameReducerActionTypes.SET_TOP_RATED_GAME,
          payload: value,
        });
      },
      [dispatch]
    ),

    setLoading: useCallback(
      (value: boolean): void => {
        return dispatch({
          type: eTopGameReducerActionTypes.SET_LOADING,
          payload: value,
        });
      },
      [dispatch]
    ),

    setError: useCallback(
      (error: string): void => {
        return dispatch({
          type: eTopGameReducerActionTypes.SET_ERROR,
          payload: error,
        });
      },
      [dispatch]
    ),
  };
};
