import { useEffect, useState } from 'react';
import { renderLink } from '../../../helpers/sectionRenderLink';
import { useBottomSectionContext } from '../context';

export const useBottomData = () => {
  const {
    state: { items },
  } = useBottomSectionContext();
  const onItemClick = (id: string) => console.log(id);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return {
    renderLink,
    pageBottomItems: items ?? [],
    onItemClick,
    isMounted,
  };
};
