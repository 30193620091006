import { useCallback, useEffect, useRef, useState } from 'react';

import { ITourGameData, TournamentBoardStatus } from 'shared/types';
import { TCreateGSSocket } from '@store/context/lobby_context/_lobby_context.types';
import { GameWSController } from '@store/context/lobby_context/ws';
import {
  eGameServerPayloadType,
  MessageType,
} from '@store/context/lobby_context/ws/_types';
import { ITopRatedGameWsMove } from '@pages/home/sections/QuickGameSection/_types';
import { useAuthStore } from '@store/storeshed';
import { eCause } from '@store/context/_types';
import { appService } from '@services/_app.service';
import { getStreamBoardMovesFromMoves } from '@pages/home/sections/QuickGameSection/utils/_getStreamBoardMovesFromMoves';
import { getStreamBoardMovesFromTurns } from '@pages/home/sections/QuickGameSection/utils/_getStreamBoardMovesFromTurns';

let timeoutId: NodeJS.Timeout;

export const useTopRatedGameWs = (
  getTopGame: () => void,
  topRatedGame?: ITourGameData | null
) => {
  const [moves, setMoves] = useState<ITopRatedGameWsMove[]>([]);
  const ws = useRef<GameWSController | null>(null);

  const playerId = useAuthStore('uid');

  const createSocket = useCallback(
    ({ settings: { boardId, playerId, region } }: TCreateGSSocket) => {
      const ws = new GameWSController();
      ws.boardId = boardId;
      ws.userType = 'viewer';
      ws.playerId = playerId;
      ws.region = region;
      return ws;
    },
    []
  );

  const getRegion = useCallback(async (gameId: string) => {
    try {
      const { data: regions } = await appService.getRegionsByGIds([gameId]);
      return regions[gameId] === 'basic' ? null : regions[gameId];
    } catch (e) {
      return null;
    }
  }, []);

  const getPlayerGame = useCallback(
    async (gameId: string, region: string | null) => {
      try {
        const { data } = region
          ? await appService.getNGPlayerRegionGames({
              gameIds: [gameId],
              region,
            })
          : await appService.getNGPlayerGames({ gameIds: [gameId] });
        if (data.length)
          setMoves(getStreamBoardMovesFromTurns(data[0].playingPh?.turns));
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  const unsubscribeFromGame = useCallback(() => {
    if (!ws?.current) return;
    ws.current.shouldReconnect = false;
    ws.current.disconnect();
    ws.current = null;
  }, [ws]);

  const subscribeToGame = useCallback(async () => {
    if (!topRatedGame) return;

    const boardId = topRatedGame?.board_id;
    const region = await getRegion(topRatedGame.board_id);
    await getPlayerGame(topRatedGame.board_id, region);

    const $ws = createSocket({
      settings: {
        boardId,
        playerId: playerId || '',
        playerSide: null,
        region,
      },
    });

    $ws.onMessage = ({ payloadType, payload }: MessageType) => {
      if (payloadType === eGameServerPayloadType.STATE) {
        switch (payload.cause) {
          case eCause.MOVED:
          case eCause.PHASE_CHANGED:
            if (payload.playingPh?.lastTurn) {
              const item = {
                fen: payload.playingPh.lastTurn.end.fen,
                lan: payload.playingPh.lastTurn.end.uci,
                moveNumber: payload.playingPh.lastTurn.start.absNum,
              };
              setMoves((state) => {
                if (!state) return [];
                const $moves = state || [];
                return [...$moves, item];
              });
            }
            if (payload.donePh?.result) {
              clearTimeout(timeoutId);
              timeoutId = setTimeout(getTopGame, 5000);
            }
            break;
          default:
            return null;
        }
      }
    };

    $ws.onClose = async () => {};
    $ws.connect();

    ws.current = $ws;
  }, [topRatedGame?.board_id, playerId]);

  useEffect(() => {
    if (topRatedGame) {
      setMoves(getStreamBoardMovesFromMoves(topRatedGame.moves));

      if (topRatedGame.status !== TournamentBoardStatus.COMPLETED) {
        subscribeToGame();
      }
    }
    return () => {
      setMoves([]);
      unsubscribeFromGame();
      clearTimeout(timeoutId);
    };
  }, [topRatedGame?.board_id]);

  return { moves, ws: ws.current, data: topRatedGame };
};
