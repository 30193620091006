import { useCallback, useEffect, useState } from 'react';
import Router from 'next/router';
import { routesConstants } from '@constants';

export const useRouteChangeHook = (onChange: (value: boolean) => void) => {
  const [mounted, setMounted] = useState(false);

  const handleRouteChange = useCallback(
    (url: string) => {
      if (!mounted) return;
      if (url.includes(routesConstants.GAME)) onChange(true);
    },
    [mounted, onChange]
  );

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleRouteChange, onChange]);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);
};
