import { gameService } from '@services/_game.service';
import { ITourGameData } from 'shared/types';

export async function getTopRatedGame(): Promise<ITourGameData> {
  const { data } = await gameService.getTopRatedGame();

  if (data) {
    return data;
  }

  throw new Error('get top rated game executed without ok status');
}
