import IconRating from '@chessarena/components/lib/icons/IconRating';
import IconFide from '@chessarena/components/lib/icons/IconFide';
import { IQuickGameTab } from '@pages/home/sections/QuickGameSection/_types';
import { HomeTabsItem } from '@chessarena/components/lib/home/HomeTabs/types';

import { GameRatingMode } from 'shared/types';

const getIcon = (id: GameRatingMode) => {
  switch (id) {
    case GameRatingMode.RATED:
      return <IconRating />;
    case GameRatingMode.FIDERATED:
      return <IconFide />;
    default:
      return null;
  }
};

export const getHomeTabs = (tabs: IQuickGameTab[]): HomeTabsItem[] => {
  return tabs.map((item) => {
    const count = item.items.filter((el) => el.active).length;
    return {
      icon: getIcon(item.id),
      title: item.title,
      value: item.id,
      count,
    };
  });
};
