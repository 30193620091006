import { FC, memo } from 'react';
import { WelcomeSectionContextProvider } from './context';
import { WelcomeFragment } from './fragments/WelcomeFragment';

export const WelcomeSection: FC = memo(function WelcomeSection() {
  return (
    <WelcomeSectionContextProvider>
      <WelcomeFragment />
    </WelcomeSectionContextProvider>
  );
});
