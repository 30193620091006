import { masterclassesService } from '@services/_masterclasses.service';
import { useApplicationContext } from '@application';
import { IRequestData } from '@types';
import { useCallback } from 'react';
import { useAccountType } from '@utils/hooks/_useAccountType.hook';
import { adaptMasterclassItemsResponse } from './_adapters';
import {
  eHomePageMasterclassContextActionType,
  THomePageMasterclassContextDispatch,
  TMasterclassItemsData,
} from './_types';

export const useMasterclassSectionContextActions = ({
  dispatch,
}: {
  // state: IHomePageMasterclassContextState;
  dispatch: THomePageMasterclassContextDispatch;
}) => {
  const { localization: l } = useApplicationContext();
  const [accountType] = useAccountType();

  const setMasterclassItemsDataRequest = useCallback(
    (value: Partial<IRequestData<TMasterclassItemsData>>) => {
      dispatch({
        type: eHomePageMasterclassContextActionType.SET_MASTERCLASSES_ITEMS_DATA_REQUEST,
        payload: value,
      });
    },
    [dispatch]
  );

  const fetchMasterclassItemsData = useCallback(async () => {
    setMasterclassItemsDataRequest({
      status: 'request',
    });

    try {
      const { data, ok } = await masterclassesService.getList({
        limit: 4,
        offset: 0,
      });

      if (ok) {
        const adaptedData = adaptMasterclassItemsResponse(data, l, accountType);

        setMasterclassItemsDataRequest({
          data: adaptedData,
          status: 'done',
        });
      }
    } catch (err) {
      setMasterclassItemsDataRequest({
        error: err as string,
        status: 'error',
      });
    }
  }, [accountType, l, setMasterclassItemsDataRequest]);

  return {
    fetchMasterclassItemsData,
  };
};
