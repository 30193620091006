import { ILocalization } from 'shared/types';

export const getCountableLocalizedText = ({
  entityAmount,
  localeKeyBase,
  localizationPart,
  textFormatCallback = (amount, text) => `${amount} ${text}`,
}: {
  localizationPart: ILocalization[keyof ILocalization];
  localeKeyBase: string;
  entityAmount: number;
  textFormatCallback?: (amount: number | string, text: string) => string;
}) => {
  const countablePostfix = entityAmount === 1 ? 'singular' : 'plural';

  return textFormatCallback(
    entityAmount,
    localizationPart[`${localeKeyBase}_${countablePostfix}`]
  );
};
