import { FC, PropsWithChildren, memo } from 'react';

import HomeTournaments from '@chessarena/components/lib/home/HomeTournaments';

import { useTournamentsData } from './_useTournamentsData.hook';
import Confirmation from '@chessarena/components/lib/general/Confirmation';
import { AlertPopup } from '@components/molecules/popups/_AlertPopup';

export const TournamentsSectionContent: FC<PropsWithChildren> = memo(
  function TournamentsSectionContent({}) {
    const {
      title,
      cardData,
      tournaments,
      listData,
      renderLink,
      onActionClick,
      isLoading,
      leaveConfirmation,
      alert,
    } = useTournamentsData();

    return (
      <>
        <HomeTournaments
          title={title}
          cardData={cardData}
          tournaments={tournaments}
          listData={listData}
          renderLink={renderLink}
          onActionClick={onActionClick}
          isLoading={isLoading}
        />
        {leaveConfirmation.open && (
          <Confirmation
            title={leaveConfirmation.title}
            labels={leaveConfirmation.labels}
            onConfirm={leaveConfirmation.confirm}
            onCancel={leaveConfirmation.cancel}
          >
            {leaveConfirmation.content}
          </Confirmation>
        )}
        {alert.isAlertPopupShown && (
          <AlertPopup
            ref={alert.alertPopupRef}
            onClick={alert.onClose}
            text={alert.alertText}
          />
        )}
      </>
    );
  }
);
