import { THomePageHeaderStoredDataResponse } from '@pages/home/_types';
import { TBestPlayerData, THeaderEventData, TStats } from './_types';
import {
  eAccessLevel,
  IBestPlayer,
  ILocalization,
  IPlayersCount,
} from 'shared/types';
import { routesConstants } from '@constants';

export const adaptHeaderResponse = (
  data?: THomePageHeaderStoredDataResponse
): THeaderEventData | undefined => {
  if (!data) return;

  return { eventImage: data?.event_icon, eventText: data?.event_text };
};

const defaultBestPlayerAvatar =
  'https://gaming-images.worldchess.com/media/default-user-avatar.svg';

const adaptRatings = (player: IBestPlayer, localization: ILocalization) => {
  const isPlayerFideRated =
    player.fide_blitz > 0 || player.fide_bullet > 0 || player.fide_rapid > 0;

  const timeControls = ['bullet', 'blitz', 'rapid'] as const;

  return timeControls.map((timeControl) => ({
    title: localization?.board_types[timeControl],
    value: String(
      isPlayerFideRated
        ? player[`fide_${timeControl}`] || 'New'
        : player[`worldchess_${timeControl}`] || 'New'
    ),
  }));
};

export const adaptBestPlayerResponse = (
  data: IBestPlayer[],
  localization: ILocalization
): TBestPlayerData | undefined => {
  if (!data?.length || !data[0]) return;

  const bestPlayer = data[0];

  return {
    link: `${routesConstants.PROFILE}/${bestPlayer.player_id}`,
    avatar: {
      src: bestPlayer.profile.avatar?.medium || defaultBestPlayerAvatar,
      premium: bestPlayer.profile.access_level === eAccessLevel.PRO,
    },
    fullName: bestPlayer.profile.full_name ?? 'Anonymous',
    ratings: adaptRatings(bestPlayer, localization),
  };
};

const formatter = new Intl.NumberFormat('ru-RU', {
  useGrouping: true,
});

const formatNumberWithSpacing = (num: number) => {
  return formatter.format(num);
};

export const adaptStatsResponse = (
  data: IPlayersCount,
  localization: ILocalization
): TStats => {
  const playersCount = formatNumberWithSpacing(data.count);
  const chessClubs = formatNumberWithSpacing(data.club_count);
  const thisWeek = formatNumberWithSpacing(data.this_week);
  const activePlayers = data.active_players;

  return {
    players: {
      title: localization.main_page.stats.players,
      value: playersCount,
    },
    chessClubs: {
      title: localization.main_page.stats.chess_clubs,
      value: chessClubs,
    },
    thisWeek: {
      title: localization.main_page.stats.this_week,
      value: thisWeek,
    },
    activePlayers,
  };
};
