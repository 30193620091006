import { IRequestData } from '@types';

import {
  IPuzzlesSectionCategoriesItem,
  IPuzzlesSectionRatingItem,
} from '../_types';

export interface IPuzzlesSectionContextStateData {
  categories: IPuzzlesSectionCategoriesItem[];
  rating: IPuzzlesSectionRatingItem[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPuzzlesSectionContextState
  extends IRequestData<IPuzzlesSectionContextStateData> {}

export interface IPuzzlesSectionContextActions {}

export interface IPuzzlesSectionContextProps {
  state: IPuzzlesSectionContextState;
  actions: IPuzzlesSectionContextActions;
}

export enum ePuzzlesSectionReducerActionTypes {
  SET_DATA,
  SET_LOADING,
  SET_ERROR,
}

interface IPuzzlesSectionReducerActionSetData {
  type: ePuzzlesSectionReducerActionTypes.SET_DATA;
  payload: IPuzzlesSectionContextStateData;
}

interface IPuzzlesSectionReducerActionSetLoading {
  type: ePuzzlesSectionReducerActionTypes.SET_LOADING;
  payload: boolean;
}

interface IPuzzlesSectionReducerActionSetError {
  type: ePuzzlesSectionReducerActionTypes.SET_ERROR;
  payload: string;
}

export type IPuzzlesSectionReducerAction =
  | IPuzzlesSectionReducerActionSetData
  | IPuzzlesSectionReducerActionSetLoading
  | IPuzzlesSectionReducerActionSetError;
