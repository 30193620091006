import {
  IApiPuzzlesCategory,
  IApiPuzzlesGetCategoriesRequestParams,
  IApiPuzzlesGetCategoriesResponseData,
  puzzlesService,
} from '@services/_puzzles.service';

import { IPuzzlesSectionCategoriesItem } from '../_types';

function getShuffled(list: IApiPuzzlesCategory[]) {
  return [...list].sort(() => Math.random() - 0.5);
}

function adaptResponseData(
  data: IApiPuzzlesGetCategoriesResponseData
): IPuzzlesSectionCategoriesItem[] {
  if (!data.results?.length) return [];
  return getShuffled(data.results)
    ?.slice(0, 3)
    .map((item) => ({
      id: item.url,
      title: item.title,
      description: item.description,
      image: item.image,
      puzzlesQty: item.puzzles_count,
      solvedPuzzlesQty: item.win_puzzles_count,
    }));
}

export async function getCategories(
  params: IApiPuzzlesGetCategoriesRequestParams = {}
): Promise<IPuzzlesSectionCategoriesItem[]> {
  const { data } = await puzzlesService.getCategories(params);

  if (data) {
    return adaptResponseData(data);
  }

  throw new Error('get puzzles categories list executed without ok status');
}
