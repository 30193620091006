import React, { FC, PropsWithChildren, memo } from 'react';

import HomeQuickGame from '@chessarena/components/lib/home/HomeQuickGame';
import { AlertPopup } from '@components/molecules/popups/_AlertPopup';
import { HomeGameSettingsPopup } from '@chessarena/components/lib/home/HomeGameSettingsPopup';

import { QuickGameSectionContextProvider } from './contexts/quick-game';
import { TopGameContextProvider } from './contexts/top-game';

import { useQuickGameData } from './_useQuickGameData.hook';

const QuickGameSectionInner: FC<PropsWithChildren> = memo(
  function QuickGameSectionInner() {
    const quickGameData = useQuickGameData();
    const { alert, inviteSettings, ...rest } = quickGameData;

    return (
      <>
        <HomeQuickGame {...rest} />
        {inviteSettings.opened && (
          <HomeGameSettingsPopup {...inviteSettings.popupProps} />
        )}
        {alert.isAlertPopupShown && (
          <AlertPopup
            ref={alert.alertPopupRef}
            onClick={alert.onClose}
            text={alert.alertText}
          />
        )}
      </>
    );
  }
);

export const QuickGameSection: FC<PropsWithChildren> = memo(
  function GamesSection() {
    return (
      <QuickGameSectionContextProvider>
        <TopGameContextProvider>
          <QuickGameSectionInner />
        </TopGameContextProvider>
      </QuickGameSectionContextProvider>
    );
  }
);
