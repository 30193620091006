import { useCallback, useMemo } from 'react';

import { ButtonType } from '@chessarena/components/lib/general/Button/types';

import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { useHomeContext } from '@pages/home/context';
import { useQuickGameSectionContext } from './contexts/quick-game';

export const useQuickGameInviteData = () => {
  const {
    actions: { setInviteSettingsOpened },
  } = useQuickGameSectionContext();

  const { data } = useHomeContext();

  const onOpen = useCallback(() => {
    setInviteSettingsOpened(true);
    gtmPush({ event: GTM_EVENTS.INVITE_A_FRIEND_CLICK });
  }, [setInviteSettingsOpened]);

  return useMemo(
    () => ({
      type: 'secondary' as ButtonType,
      label: data?.sections.quick_game.invite.action || '',
      width: '100%',
      onClick: onOpen,
    }),
    [data, onOpen]
  );
};
