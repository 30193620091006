import { useBoardSettingsData } from '@store/storeshed';
import { useTopGameContext } from '@pages/home/sections/QuickGameSection/contexts/top-game';
import { useMounted } from '@utils/hooks/_useMounted.hook';

export const useTopGameBoardData = (boardSize: number) => {
  const {
    computed: { topRatedGameWs },
  } = useTopGameContext();

  const mounted = useMounted();

  const boardTheme = useBoardSettingsData('boardTheme');
  const currentLastMoveTheme = useBoardSettingsData('lastMoveTheme');

  return {
    visible: mounted,
    history: topRatedGameWs?.moves || [],
    chessboardSize: boardSize,
    theme: boardTheme,
    lmTheme: currentLastMoveTheme,
  };
};
