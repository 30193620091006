import { IRequestData } from '@types';

export type THeaderEventData = {
  eventImage?: string;
  eventText?: string;
};

export type BestPlayerRating = {
  title: string;
  value: string;
};

export type TBestPlayerData = {
  link: string;
  fullName: string;
  avatar: {
    src: string;
    premium: boolean;
  };
  ratings: BestPlayerRating[];
};

export type TStat = {
  title: string;
  value: string;
};

export type TStats = {
  players: TStat;
  thisWeek: TStat;
  chessClubs: TStat;
  activePlayers: number;
};

export interface IHomePageHeaderContextState {
  eventData?: THeaderEventData;
  bestPlayer: IRequestData<TBestPlayerData | undefined>;
  stats: IRequestData<TStats | undefined>;
}

export interface IHomePageHeaderContextActions {}

export interface IHeaderSectionContext {
  state: IHomePageHeaderContextState;
  actions: IHomePageHeaderContextActions;
}

export enum eHomePageHeaderContextActionType {
  SET_EVENT_DATA_REQUEST = 'SET_EVENT_DATA_REQUEST',
  SET_BEST_PLAYER_REQUEST = 'SET_BEST_PLAYER_REQUEST',
  SET_STATS_REQUEST = 'SET_STATS_REQUEST',
}

interface IHomePageHeaderActionMessage {
  type: eHomePageHeaderContextActionType;
}

export type THomePageHeaderContextDispatch =
  React.Dispatch<THomePageHeaderAction>;

export type THomePageHeaderContextReducer = React.Reducer<
  IHomePageHeaderContextState,
  THomePageHeaderAction
>;

interface ISetEventDataRequest extends IHomePageHeaderActionMessage {
  type: eHomePageHeaderContextActionType.SET_EVENT_DATA_REQUEST;
  payload: Partial<IRequestData<THeaderEventData>>;
}

interface ISetBestPlayerDataRequest extends IHomePageHeaderActionMessage {
  type: eHomePageHeaderContextActionType.SET_BEST_PLAYER_REQUEST;
  payload: Partial<IRequestData<TBestPlayerData>>;
}

interface ISetStatsDataRequest extends IHomePageHeaderActionMessage {
  type: eHomePageHeaderContextActionType.SET_STATS_REQUEST;
  payload: Partial<IRequestData<TStats>>;
}

export type THomePageHeaderAction =
  | ISetEventDataRequest
  | ISetBestPlayerDataRequest
  | ISetStatsDataRequest;
