import { ITurn } from '@store/context/_types';

export const getStreamBoardMovesFromTurns = (turns?: ITurn[] | null) => {
  return (
    turns?.map((item) => ({
      fen: item.end.fen,
      lan: item.end.uci,
      moveNumber: item.start.absNum,
    })) || []
  );
};
