import {
  eTopGameReducerActionTypes,
  ITopGameReducerAction,
  ITopGameContextState,
} from './_types';

const initStateData = {
  topRatedGame: null,
};

export const createInitState = (): ITopGameContextState => ({
  status: 'request',
  error: null,
  data: {
    topRatedGame: initStateData.topRatedGame,
  },
});

export const TopGameReducer = (
  prevState: ITopGameContextState,
  { type, payload }: ITopGameReducerAction
): ITopGameContextState => {
  switch (type) {
    case eTopGameReducerActionTypes.SET_TOP_RATED_GAME:
      return {
        ...prevState,
        data: {
          ...prevState.data,
          topRatedGame: payload,
        },
      };
    case eTopGameReducerActionTypes.SET_LOADING:
      return {
        ...prevState,
        status: payload ? 'request' : 'done',
      };
    case eTopGameReducerActionTypes.SET_ERROR:
      return {
        ...prevState,
        status: 'error',
        error: payload,
      };
    default:
      throw new Error('undefined type of action');
  }
};
