import dayjs from 'dayjs';

import { HomeTournament } from '@chessarena/components/lib/home/HomeTournamentCard/types';

import {
  ILocalization,
  ITournamentData,
  ITournamentPlayer,
  eAccessLevel,
} from 'shared/types';
import {
  getTournamentPlayersAmount,
  getTournamentType,
} from 'shared/helpers/_tournament';
import { getLocalizedTimeControl } from 'shared/helpers/_game';

import { routesConstants } from '@constants';
import { getCountableLocalizedText } from '@utils/_localization';

import { ITournamentExpandedWithPlayersData } from '../../../_types';
import { ratingTypesMap } from '@utils/_tournament';
import { UserStatus } from '@utils/hooks/_useUserSatatus';
import { eFideIdStatus } from '@types';
import { getActionAndMessage } from './_getActionAndMessage';

function getPlayerList(players?: ITournamentPlayer[]) {
  if (!players) return [];

  return players.map((item) => ({
    id: item.player_id.toString(),
    firstname: item.full_name,
    lastname: '',
    avatar: {
      src: item.avatar_thumbnails?.medium || undefined,
      premium: item.access_level === eAccessLevel.PRO,
    },
  }));
}

export function getFeaturedTournament(
  tournament: ITournamentExpandedWithPlayersData,
  my: ITournamentData[],
  userStatus: UserStatus,
  fideIdStatus: eFideIdStatus,
  disabledItems: number[],
  l: ILocalization
): HomeTournament {
  const slug = tournament.slug || tournament.id.toString();
  const { action, message } = getActionAndMessage(
    tournament,
    my,
    userStatus,
    fideIdStatus,
    disabledItems,
    l
  );

  return {
    id: tournament.id.toString(),
    link: `${routesConstants.TOURNAMENTS}/${slug}`,
    title: tournament.title || '',
    description: tournament.description ? (
      <div dangerouslySetInnerHTML={{ __html: tournament.description }} />
    ) : undefined,
    imageSrc:
      tournament.main_image?.full ||
      'https://gaming-images.worldchess.com/media/tournament/default_image/medium.png',
    date: dayjs(tournament.start).format('MMM DD, HH:mm'),
    ratingType: ratingTypesMap[tournament.rating_type],
    timeControl: getLocalizedTimeControl(
      tournament.time_control,
      l.board_types,
      l.time?.minutes
    ),
    tournamentType: l.tournament_types?.[getTournamentType(tournament.kind)],
    playersList: getPlayerList(tournament.players?.data),
    playersLabel: `${getTournamentPlayersAmount({
      registeredPlayers: tournament.registered_players,
      signedUpAmount: tournament.signed_up_amount,
      allowedAmount: tournament.max_participants,
      tournamentStatus: tournament.status,
    })} ${l.common?.players}`,
    countriesLabel: getCountableLocalizedText({
      entityAmount: tournament.players?.countries_amount || 0,
      localeKeyBase: l.common?.countries,
      localizationPart: l.tournament_page,
    }),
    action,
    message,
  };
}

export function getFeaturedTournaments(
  featured: ITournamentExpandedWithPlayersData[],
  my: ITournamentData[],
  userStatus: UserStatus,
  fideIdStatus: eFideIdStatus,
  disabledItems: number[],
  l: ILocalization
): HomeTournament[] {
  const result: HomeTournament[] = [];

  if (featured.length) {
    featured.forEach((item) => {
      result.push(
        getFeaturedTournament(
          item,
          my,
          userStatus,
          fideIdStatus,
          disabledItems,
          l
        )
      );
    });
  }

  return result.slice(0, 4);
}
