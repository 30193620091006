"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeMasterclasessListItemSkeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../style");
const Skeleton_1 = require("../../../general/Skeleton");
exports.HomeMasterclasessListItemSkeleton = (0, react_1.memo)(function HomeMasterclasessListItemSkeleton({ index }) {
    return ((0, jsx_runtime_1.jsx)(style_1.$HomeMasterclassesListItem, { "$gridColumn": index === 0 && '2 / 5', children: (0, jsx_runtime_1.jsx)(Skeleton_1.Skeleton, { height: "100%", minHeight: index === 0 ? '200px' : '360px' }) }));
});
