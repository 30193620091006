import {
  ePuzzlesSectionReducerActionTypes,
  IPuzzlesSectionReducerAction,
  IPuzzlesSectionContextState,
} from './_types';

const initStateData = {
  categories: [],
  rating: [],
};

export const createInitState = (): IPuzzlesSectionContextState => ({
  status: 'request',
  error: null,
  data: {
    categories: initStateData.categories,
    rating: initStateData.rating,
  },
});

export const puzzlesSectionReducer = (
  prevState: IPuzzlesSectionContextState,
  { type, payload }: IPuzzlesSectionReducerAction
): IPuzzlesSectionContextState => {
  switch (type) {
    case ePuzzlesSectionReducerActionTypes.SET_DATA:
      return {
        ...prevState,
        status: 'done',
        data: payload,
      };
    case ePuzzlesSectionReducerActionTypes.SET_LOADING:
      return {
        ...prevState,
        status: payload ? 'request' : 'done',
      };
    case ePuzzlesSectionReducerActionTypes.SET_ERROR:
      return {
        ...prevState,
        status: 'error',
        error: payload,
        data: {
          ...initStateData,
        },
      };
    default:
      throw new Error('undefined type of action');
  }
};
