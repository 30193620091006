import { FC, PropsWithChildren, useEffect, useMemo, useReducer } from 'react';

import { useHeaderSectionContextActions } from './_actions';
import { HeaderSectionContext } from './_context';
import { homePageHeaderContextReducer } from './_reducer';
import { IHeaderSectionContext, IHomePageHeaderContextState } from './_types';
import { useHomeContext } from '@pages/home/context';
import { IHomePageData } from '@pages/home/_types';
import { adaptHeaderResponse } from './_adapters';

const initialState: IHomePageHeaderContextState = {
  eventData: undefined,
  bestPlayer: {
    data: undefined,
    status: 'not_requested',
    error: null,
  },
  stats: {
    data: undefined,
    status: 'not_requested',
    error: null,
  },
};

const createInitialState = (
  homeContextData?: IHomePageData
): IHomePageHeaderContextState => {
  const eventData = adaptHeaderResponse(homeContextData?.sections?.header);

  return {
    ...initialState,
    eventData,
  };
};

export const HeaderSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();

  const [state, dispatch] = useReducer(
    homePageHeaderContextReducer,
    createInitialState(data)
  );

  const actions = useHeaderSectionContextActions({ dispatch });

  const value = useMemo<IHeaderSectionContext>(
    () => ({
      state,
      actions,
    }),
    [actions, state]
  );

  useEffect(() => {
    actions.fetchBestPlayerData();
    actions.fetchStatsData();
  }, [actions.fetchBestPlayerData, actions.fetchStatsData]);

  return (
    <HeaderSectionContext.Provider value={value}>
      {children}
    </HeaderSectionContext.Provider>
  );
};
