"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTopGameBoardSize = void 0;
const react_1 = require("react");
const useTopGameBoardSize = (nodeRef) => {
    const [size, setSize] = (0, react_1.useState)(0);
    const setOffset = (0, react_1.useCallback)(() => {
        if (nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.current) {
            const rect = nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.current.getBoundingClientRect();
            setSize(rect.width);
        }
    }, [nodeRef]);
    (0, react_1.useEffect)(() => {
        setOffset();
        window.addEventListener('resize', setOffset);
        return () => {
            window.removeEventListener('resize', setOffset);
        };
    }, [setOffset]);
    return size;
};
exports.useTopGameBoardSize = useTopGameBoardSize;
