import { FC } from 'react';

import { HomeTournamentsContextProvider } from './context';
import { TournamentsSectionContent } from './TournamentsSectionContent';

export const TournamentsSection: FC = () => (
  <HomeTournamentsContextProvider>
    <TournamentsSectionContent />
  </HomeTournamentsContextProvider>
);
