import {
  HomeMasterclassesItem,
  HomeMasterclassQuoteListItem,
} from '@chessarena/components/lib/home/HomeMasterclasses/types';
import { HomeSectionCard } from '@chessarena/components/lib/home/HomeSectionCard/types';
import { IRequestData } from '@types';

export type TMasterclassItemsData = HomeMasterclassesItem[];
export type TMasterclassesData = {
  quote: HomeMasterclassQuoteListItem;
  card: HomeSectionCard;
};

export interface IHomePageMasterclassContextState {
  masterclassItems: IRequestData<TMasterclassItemsData | undefined>;
  masterclassData: TMasterclassesData | undefined;
}

export interface IHomePageMasterclassContextActions {}

export interface IMasterclassSectionContext {
  state: IHomePageMasterclassContextState;
  actions: IHomePageMasterclassContextActions;
}

export enum eHomePageMasterclassContextActionType {
  SET_MASTERCLASSES_ITEMS_DATA_REQUEST = 'SET_MASTERCLASSES_ITEMS_DATA_REQUEST',
}

interface IHomePageMasterclassActionMessage {
  type: eHomePageMasterclassContextActionType;
}

export type THomePageMasterclassContextDispatch =
  React.Dispatch<THomePageMasterclassAction>;

export type THomePageMasterclassContextReducer = React.Reducer<
  IHomePageMasterclassContextState,
  THomePageMasterclassAction
>;

interface ISetMasterclassItemsDataRequest
  extends IHomePageMasterclassActionMessage {
  type: eHomePageMasterclassContextActionType.SET_MASTERCLASSES_ITEMS_DATA_REQUEST;
  payload: Partial<IRequestData<TMasterclassItemsData>>;
}

export type THomePageMasterclassAction = ISetMasterclassItemsDataRequest;
