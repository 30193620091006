import { createContext, useContext } from 'react';

import { IHomeTournamentsContextValue } from './_types';

export const HomeTournamentsContext = createContext(
  {} as IHomeTournamentsContextValue
);

export const useHomeTournamentsContext = () =>
  useContext(HomeTournamentsContext);
