import { useMemo } from 'react';

import { routesConstants } from '@constants';

import { useHomeContext } from '../../context';
import HomeIconHexagonLove from '@chessarena/components/lib/home/HomeIcons/HomeIconHexagonLove';

export const usePuzzlesDataRated = (
  withLink?: boolean,
  onClick?: (id: string) => void
) => {
  const { data } = useHomeContext();

  return useMemo(() => {
    const ratedLink = withLink ? routesConstants.PUZZLES_RATED : undefined;

    return {
      id: 'rated',
      link: ratedLink,
      image: <HomeIconHexagonLove />,
      button: {
        label: data?.sections?.puzzles?.items?.rated?.title,
        onClick,
      },
    };
  }, [data, withLink, onClick]);
};
