import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { IHomePageData } from '@pages/home/_types';
import { useHomeContext } from '@pages/home/context';
import { adaptWelcomeResponse } from './_adapters';
import { WelcomeSectionContext } from './_context';
import { IWelcomeContextState, IWelcomeSectionContext } from './_types';

const initialState: IWelcomeContextState = {
  welcome: undefined,
};

const createInitialState = (
  homeContextData?: IHomePageData
): IWelcomeContextState => {
  const welcome = adaptWelcomeResponse(homeContextData?.sections.welcome);
  return { ...initialState, welcome };
};

export const WelcomeSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();
  const [state] = useState(createInitialState(data));

  const value = useMemo<IWelcomeSectionContext>(
    () => ({
      state,
    }),
    [state]
  );

  return (
    <WelcomeSectionContext.Provider value={value}>
      {children}
    </WelcomeSectionContext.Provider>
  );
};
