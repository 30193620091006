import { useButtonData } from './useButtonData.hook';
import { useEventData } from './useEventData.hook';
import { useInfoData } from './useInfoData.hook';
import { usePlayersItems } from './usePlayersItems.hook';

export const useHeaderData = () => {
  const players = usePlayersItems();
  const event = useEventData();
  const info = useInfoData();
  const button = useButtonData();

  return { event, players, button, info };
};
