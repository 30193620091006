import { useMemo } from 'react';

import { useHomeContext } from '@pages/home/context';
import { useGameSearchData } from '@utils/hooks/_useGameSearchData.hook';

export const useQuickGameInfoData = () => {
  const { data } = useHomeContext();

  const search = useGameSearchData();

  return useMemo(() => {
    return {
      title: data?.sections?.quick_game.title || '',
      description: data?.sections?.quick_game.description || '',
      search,
    };
  }, [data, search]);
};
