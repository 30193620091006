import { createContext, useContext } from 'react';

import { IMasterclassSectionContext } from './_types';

export const MasterclassSectionContext = createContext(
  {} as IMasterclassSectionContext
);

export const useMasterclassSectionContext = () =>
  useContext(MasterclassSectionContext);
