import { FC, PropsWithChildren, useEffect, useMemo, useReducer } from 'react';

import { useMasterclassSectionContextActions } from './_actions';
import { MasterclassSectionContext } from './_context';
import { homePageMasterclassContextReducer } from './_reducer';
import {
  IMasterclassSectionContext,
  IHomePageMasterclassContextState,
} from './_types';
import { IHomePageData } from '@pages/home/_types';
import { adaptMasterclassDataResponse } from './_adapters';
import { useHomeContext } from '@pages/home/context';

const initialState: IHomePageMasterclassContextState = {
  masterclassItems: {
    data: [],
    status: 'not_requested',
    error: null,
  },
  masterclassData: undefined,
};

const createInitialState = (
  homeContextData?: IHomePageData
): IHomePageMasterclassContextState => {
  const masterclassData = adaptMasterclassDataResponse(
    homeContextData?.sections.masterclasses
  );

  return {
    ...initialState,
    masterclassData,
  };
};

export const MasterclassSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();
  const [state, dispatch] = useReducer(
    homePageMasterclassContextReducer,
    createInitialState(data)
  );

  const actions = useMasterclassSectionContextActions({ dispatch });

  const value = useMemo<IMasterclassSectionContext>(
    () => ({
      state,
      actions,
    }),
    [actions, state]
  );

  useEffect(() => {
    actions.fetchMasterclassItemsData();
  }, [actions.fetchMasterclassItemsData]);

  return (
    <MasterclassSectionContext.Provider value={value}>
      {children}
    </MasterclassSectionContext.Provider>
  );
};
