import HomeHeader from '@chessarena/components/lib/home/HomeHead';
import { FC, memo } from 'react';
import { useHeaderData } from '../hooks/useHeaderData.hook';

export const HomeHeaderFragment: FC = memo(function HomeHeaderFragment() {
  const { button, event, info, players } = useHeaderData();

  return (
    <HomeHeader players={players} event={event} info={info} button={button} />
  );
});
