import { useMasterclassSectionContext } from '../context';

export const useMasterclassItems = () => {
  const {
    state: {
      masterclassItems: { data, status },
    },
  } = useMasterclassSectionContext();

  return { masterclassItems: data, isLoading: status !== 'done' };
};
