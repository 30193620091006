import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAuthStore } from '@store/storeshed';

import { getTournamentsData } from '../helpers/_getTournamentsData';

import { HomeTournamentsContext } from './_context';
import {
  IHomeTournamentsContextData,
  IHomeTournamentsContextValue,
} from './_types';

export const HomeTournamentsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IHomeTournamentsContextData>();

  const logged = useAuthStore('logged');

  const getData = useCallback(async (hidden: boolean = false) => {
    try {
      setLoading(!hidden);
      const data = await getTournamentsData();
      setData(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const value = useMemo<IHomeTournamentsContextValue>(() => {
    return {
      loading,
      data,
      getData,
    };
  }, [loading, data, getData]);

  useEffect(() => {
    getData();
  }, [getData, logged]);

  return (
    <HomeTournamentsContext.Provider value={value}>
      {children}
    </HomeTournamentsContext.Provider>
  );
};
