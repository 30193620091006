import { HomePuzzlesCategories } from '@chessarena/components/lib/home/HomePuzzles/types';

import { routesConstants } from '@constants';
import { IPuzzlesSectionCategoriesItem } from '@pages/home/sections/PuzzlesSection/_types';

export function getPuzzlesListCategories(
  items: IPuzzlesSectionCategoriesItem[],
  withLink?: boolean,
  onClick?: () => void
): HomePuzzlesCategories {
  return {
    items: items.map((item) => {
      const link =
        withLink && item.puzzlesQty > item.solvedPuzzlesQty
          ? `${routesConstants.PUZZLES}/${item.id}`
          : undefined;

      return {
        link,
        id: item.id,
        image: item.image,
        button: {
          label: item.title,
          onClick,
        },
      };
    }),
  };
}
