import { useCallback, useEffect, useMemo } from 'react';

import { eAccessLevel, GameRatingMode } from 'shared/types';

import { useQuickGameSectionContext } from './contexts/quick-game';

import { getHomeTabs } from '@pages/home/sections/QuickGameSection/utils/_getHomeTabs';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { eFideIdStatus } from '@types';

export const useQuickGameTabsData = () => {
  const {
    state: {
      data: { activeTabId },
    },
    computed: { tabs },
    actions: { setActiveTabId },
  } = useQuickGameSectionContext();

  const items = useMemo(() => getHomeTabs(tabs), [tabs]);

  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const fideIdStatus = useFideIdStatus();
  const accessLevel = userData?.access_level;

  const tabIdByUser = useMemo(() => {
    if (!logged) return GameRatingMode.UNRATED;
    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.CONNECTED) {
        return GameRatingMode.FIDERATED;
      }
    }
    return GameRatingMode.RATED;
  }, [logged, fideIdStatus, accessLevel]);

  const onClick = useCallback(
    (value: string) => {
      setActiveTabId(value as GameRatingMode);
    },
    [setActiveTabId]
  );

  useEffect(() => {
    setActiveTabId(tabIdByUser);
  }, []);

  return {
    value: activeTabId || '',
    items,
    onClick,
  };
};
