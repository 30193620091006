import {
  ITournamentData,
  ITournamentParams,
  TournamentStatus,
} from 'shared/types';
import { syncedMoment } from 'shared/helpers/_common';

import { lsKeys } from '@types';
import { tournamentsService } from '@services/_tournaments.service';
import { tournamentService } from '@services/_tournament.service';
import { getCookie } from '@utils/_cookies';

import { ITournamentExpandedWithPlayersData } from '../../../_types';

import { IHomeTournamentsContextData } from '../context/_types';

async function getExpandedTournament(
  id: number
): Promise<ITournamentExpandedWithPlayersData> {
  const jwt = getCookie(lsKeys.JWT);

  const tournament = await Promise.allSettled([
    tournamentService.getTournament(id, jwt),
    tournamentsService.getTournamentStanding({ id, limit: 4 }, jwt),
  ]);

  if (tournament[0].status === 'rejected') throw tournament[0].reason;

  const data =
    tournament[0].status === 'fulfilled' ? tournament[0].value.data : undefined;
  const players =
    tournament[1].status === 'fulfilled' ? tournament[1].value : undefined;

  if (!data) {
    throw new Error('getTournament executed without ok status');
  }

  return { ...data, players: players?.data };
}

async function getExpandedTournaments(
  tournaments: ITournamentData[]
): Promise<ITournamentExpandedWithPlayersData[]> {
  const expanded = await Promise.allSettled(
    tournaments.map((item) => getExpandedTournament(item.id))
  );

  return expanded.map((item, i) => {
    return item.status === 'fulfilled'
      ? item.value
      : (tournaments[i] as ITournamentExpandedWithPlayersData);
  });
}
async function getTournaments(
  params: ITournamentParams,
  prev: ITournamentData[]
) {
  params.offset = params.offset || 0;
  params.limit = params.limit || 100;

  const { data } = await tournamentsService.getTournaments(params);

  if (data.next) {
    return await getTournaments(
      { ...params, offset: params.offset + params.limit },
      [...prev, ...data.results]
    );
  }

  return [...prev, ...data.results];
}

async function getScheduledTournaments() {
  const params = {
    minStartTime: syncedMoment().utcOffset(0).format('MM/DD/YYYY HH:mm:ss'),
    maxStartTime: syncedMoment()
      .utcOffset(0)
      .add(2, 'days')
      .format('MM/DD/YYYY HH:mm:ss'),
    sortBy: 'undefined',
  };

  return await getTournaments(params, []);
}

async function getFeaturedTournaments(): Promise<ITournamentData[]> {
  const params = {
    statuses: [TournamentStatus.EXPECTED, TournamentStatus.GOES],
    featured: true,
    limit: 4,
  };

  const { data } = await tournamentsService.getTournaments(params);

  return data.results;
}

async function getMyTournaments() {
  const jwt = getCookie(lsKeys.JWT);

  if (!jwt) return [];

  const params = {
    statuses: [TournamentStatus.EXPECTED, TournamentStatus.GOES],
  };

  const { data } = await tournamentsService.getMyUpcomingTournaments(
    params,
    jwt
  );

  return data.results;
}

export async function getTournamentsData(): Promise<IHomeTournamentsContextData> {
  const tournaments = await Promise.allSettled([
    getFeaturedTournaments(),
    getScheduledTournaments(),
    getMyTournaments(),
  ]);

  const featuredList =
    tournaments[0].status === 'fulfilled' ? tournaments[0].value : [];
  const scheduled =
    tournaments[1].status === 'fulfilled' ? tournaments[1].value : [];
  const my = tournaments[2].status === 'fulfilled' ? tournaments[2].value : [];

  let length = featuredList.length;

  if (featuredList.length < 4) {
    for (const tournament of scheduled) {
      if (length === 4) {
        break;
      }
      if (!featuredList.find((item) => item.id === tournament.id)) {
        featuredList.push(tournament);
        length += 1;
      }
    }
  }

  const featured = await getExpandedTournaments(featuredList);

  return { featured, scheduled, my };
}
