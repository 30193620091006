import {
  HomeHeadButtonType,
  HomeHeadButtonIcon,
} from '@chessarena/components/lib/home/HomeHeadButton/types';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';
import { ePopupPaths } from '@constants';
import { useApplicationContext } from '@application';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { eFideIdStatus } from '@types';
import { openPopup } from '@utils/_router';
import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';
import { useMemo } from 'react';

export const useButtonData = () => {
  const logged = useAuthStore('logged');
  const userData = useUserDataStore('data');
  const userStatus = useUserStatus();
  const fideIdStatus = useFideIdStatus();

  const { localization: l } = useApplicationContext();

  const buttonData = useMemo(() => {
    if (!logged) {
      return {
        type: 'green' as HomeHeadButtonType,
        icon: 'king' as HomeHeadButtonIcon,
        label: l?.main_page?.header?.create_free_account,
        onClick: () => {
          openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
          return;
        },
      };
    }

    if (userStatus !== UserStatus.SUBSCRIBED) {
      return {
        type: 'yellow' as HomeHeadButtonType,
        icon: 'king' as HomeHeadButtonIcon,
        label: l?.main_page?.header?.upgrade_to_pro,
        onClick: () => {
          openPopup(
            `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.GAMES}`
          );
          return;
        },
      };
    }

    if (fideIdStatus === eFideIdStatus.NOT_CONNECTED) {
      return {
        type: 'capri' as HomeHeadButtonType,
        icon: 'foa' as HomeHeadButtonIcon,
        label: l?.main_page?.header?.connect_fide_id,
        onClick: () => {
          openPopup(
            `?popup=${ePopupPaths.FIDE}${
              userData?.free_account_requested ? '&free_pro=true' : ''
            }`
          );
          return;
        },
      };
    }

    return undefined;
  }, [
    fideIdStatus,
    l?.main_page?.header?.connect_fide_id,
    l?.main_page?.header?.create_free_account,
    l?.main_page?.header?.upgrade_to_pro,
    logged,
    userData?.free_account_requested,
    userStatus,
  ]);

  return buttonData;
};
