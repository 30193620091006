import HomeMasterclasses from '@chessarena/components/lib/home/HomeMasterclasses';
import { FC, memo } from 'react';
import { useMasterclassData } from '../hooks/useMasterclassData.hook';

export const HomeMasterclassFragment: FC = memo(
  function HomeMasterclassFragment() {
    const {
      cardData,
      masterclassesItems,
      masterclassesQuote,
      renderImage,
      renderLink,
      onActionClick,

      isLoading,
    } = useMasterclassData();

    return (
      <HomeMasterclasses
        title="Masterclasses"
        cardData={cardData}
        items={masterclassesItems}
        quote={masterclassesQuote}
        renderImage={renderImage}
        renderLink={renderLink}
        onActionClick={onActionClick}
        isLoading={isLoading}
      />
    );
  }
);
