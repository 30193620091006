import {
  eHomePageMasterclassContextActionType,
  THomePageMasterclassContextReducer,
} from './_types';

export const homePageMasterclassContextReducer: THomePageMasterclassContextReducer =
  (state, { type, payload }) => {
    switch (type) {
      case eHomePageMasterclassContextActionType.SET_MASTERCLASSES_ITEMS_DATA_REQUEST:
        return {
          ...state,
          masterclassItems: {
            ...state.masterclassItems,
            ...payload,
          },
        };

      default:
        return state;
    }

    return state;
  };
