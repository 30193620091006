"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreparedItems = void 0;
const getPreparedItems = (items = [], quote) => {
    const result = [];
    items.slice(0, 3).forEach((item) => {
        result.push({ id: item.id, masterclass: item });
    });
    if (quote)
        result.push({ id: quote.id, quote });
    items.slice(3).forEach((item) => {
        result.push({ id: item.id, masterclass: item });
    });
    return result;
};
exports.getPreparedItems = getPreparedItems;
