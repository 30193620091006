"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../style");
const HomeTabsDesktopItem = (props) => {
    const { selected, width, title, value, count, onClick } = props;
    const handleClick = (0, react_1.useCallback)(() => {
        onClick(value);
    }, [onClick, value]);
    return ((0, jsx_runtime_1.jsxs)(style_1.$HomeTabsDesktopItem, { "data-component": "HomeTabsDesktopItem", "data-id": value, "$active": selected === value, "$width": width, onClick: handleClick, children: [props.icon, (0, jsx_runtime_1.jsx)(style_1.$HomeTabsDesktopItemTitle, { children: title }), !!count && ((0, jsx_runtime_1.jsx)(style_1.$HomeTabsDesktopItemCount, { children: (0, jsx_runtime_1.jsx)(style_1.$HomeTabsDesktopItemCountInner, { "$active": selected === value, children: count }) }))] }));
};
exports.default = HomeTabsDesktopItem;
