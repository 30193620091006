import { useMemo } from 'react';

import { usePuzzlesSectionContext } from './context';
import { getPuzzlesListCategories } from './utils/_getPuzzlesListCategories';

export const usePuzzlesDataCategories = (
  withLink?: boolean,
  onClick?: () => void
) => {
  const {
    state: {
      data: { categories },
    },
  } = usePuzzlesSectionContext();

  return useMemo(
    () => getPuzzlesListCategories(categories, withLink, onClick),
    [categories, withLink, onClick]
  );
};
