import { routesConstants } from '@constants';
import { ratingTypes } from '@chessarena/components/lib/common/constants';
import { IPuzzlesSectionRatingItem } from '@pages/home/sections/PuzzlesSection/_types';
import { HomePuzzlesRatingListItem } from '@chessarena/components/lib/home/HomePuzzles/types';
import { IHomePageData } from '@pages/home/_types';

export function getPuzzlesListRating(
  items: IPuzzlesSectionRatingItem[],
  withLink?: boolean,
  data?: IHomePageData
): HomePuzzlesRatingListItem | undefined {
  return {
    id: 'rating',
    link: withLink
      ? `${routesConstants.PLAYERS_TOP}/${ratingTypes.worldchess}?sort=puzzle`
      : undefined,
    title: data?.sections?.puzzles?.items?.rating?.title || '',
    captions: {
      standings: data?.sections?.puzzles?.items?.rating?.standings || '',
      rating: data?.sections?.puzzles?.items?.rating?.rating || '',
    },
    items:
      items?.map((item) => ({
        ...item,
        rating: item.rating,
        player: {
          ...item.player,
          firstname: item.player.firstname || '',
          lastname: item.player.lastname || '',
          title: item.player.title || undefined,
          avatar: {
            ...item.player.avatar,
            src: item.player.avatar.src || undefined,
          },
        },
      })) || [],
  };
}
