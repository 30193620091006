import { HomeMasterclassesItemAction } from '@chessarena/components/lib/home/HomeMasterclassesItem/types';
import date from '@chessarena/components/lib/utils/date';
import { routesConstants } from '@constants';
import { THomePageMasterclassesStoredDataResponse } from '@pages/home/_types';
import { IApiMasterclassesGetListResponseData } from '@services/_masterclasses.service';
import { AccountType } from '@types';
import { ILocalization } from 'shared/types';
import { TMasterclassesData, TMasterclassItemsData } from './_types';
import { getIsUpcomingMasterclass } from './_utils';

const formatDateForDetails = (dateTime: string) => {
  if (!dateTime) return '';
  return date.dayjs(dateTime).format('[on] MMMM D, YYYY • HH:mm');
};

const formatDateForBottomPart = (dateTime: string) => {
  if (!dateTime) return '';
  return date.dayjs(dateTime).format('MMMM D [at] HH:mm');
};

const getActionByAccountType = (
  accountType: AccountType,
  localization: ILocalization
): HomeMasterclassesItemAction => {
  switch (accountType) {
    case 'free':
      return {
        id: 'upgrade',
        title: localization?.main_page.masterclasses.upgrade_to_pro,
        type: 'yellow',
      };
    case 'anonymous':
      return {
        id: 'anon-dive-into',
        title: localization?.main_page.masterclasses.dive_into_masterclass,
      };
    case 'subscribed':
      return {
        id: 'subscribed-dive-into',
        title: localization?.main_page.masterclasses.dive_into_masterclass,
      };
  }
};

export const adaptMasterclassItemsResponse = (
  data: IApiMasterclassesGetListResponseData,
  localization: ILocalization,
  accountType: AccountType
): TMasterclassItemsData => {
  if (!data.results) return [];

  const action = getActionByAccountType(accountType, localization);

  return data.results.map((masterclass, index) => {
    const isUpcoming = getIsUpcomingMasterclass(
      masterclass.translation_datetime
    );

    return {
      id: masterclass.url,
      title: masterclass.title,
      link: isUpcoming
        ? undefined
        : `${routesConstants.MASTERCLASSES}/${masterclass.url}`,
      subTitle: masterclass.description,
      backgroundSrc: masterclass.video_preview_background ?? '',
      foregroundSrc: masterclass.video_preview_foreground ?? '',
      emoticonSrc: masterclass.video_preview_emoticon ?? '',

      dateTime: formatDateForBottomPart(masterclass.translation_datetime),
      author: {
        firstname: masterclass.author.first_name,
        lastname: masterclass.author.last_name,
      },
      ...(index === 0
        ? {
            detail: {
              liveSession:
                localization?.main_page.masterclasses.live_session_with,
              dateTime: formatDateForDetails(masterclass.translation_datetime),
            },
            action,
          }
        : undefined),
    };
  });
};

export const adaptMasterclassDataResponse = (
  data?: THomePageMasterclassesStoredDataResponse
): TMasterclassesData | undefined => {
  if (!data) return;

  return {
    quote: {
      id: 'quote',
      text: data.quote.text,
      author: {
        description: data.quote.source,
      },
    },
    card: {
      description: data.card.description,
      helpItems: data.card.helpItems,
      viewAll: {
        href: `${routesConstants.MASTERCLASSES}`,
        text: data.card.viewAll,
      },
    },
  };
};
