import { createAuthorizationHeaders } from '@utils/_requests';
import { combineURLParamsByMap } from '@utils/_url_helpers';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export interface IApiPuzzlesPuzzle {
  id: number;
  fen_initial: string;
  fens: string[];
  moves: string[];
}

export interface IApiPuzzlesCategory {
  id: number;
  title: string;
  description: string;
  url: string;
  image: string;
  puzzles_count: number;
  win_puzzles_count: number;
}

export interface IApiPuzzlesGetCategoriesRequestParams {
  limit?: number;
  offset?: number;
  with_count?: boolean;
}

export interface IApiPuzzlesGetCategoriesResponseData {
  count: number;
  results: IApiPuzzlesCategory[];
}

export interface IApiPuzzlesCheckPuzzleRequestData {
  moves: string[];
  rated: boolean;
}

export interface IApiPuzzlesCheckPuzzleResponseData {
  win: boolean;
  moves: string[];
  puzzles_rating: number | null;
  puzzles_player_rating: number | null;
  puzzles_player_rating_delta: number | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApiPuzzlesGetPuzzleByCategoryResponseData
  extends IApiPuzzlesPuzzle {}

const SERVICE_URLS = {
  GET_PUZZLESS_CATEGORIES: '/learning/puzzles/categories/',
  GET_PUZZLE_BY_CATEGORY: '/learning/puzzles/categories/:category/get-puzzle/',
  GET_RATED_PUZZLE: '/learning/puzzles/rated/get-puzzle/',
  CHECK_PUZZLE: 'learning/puzzles/:id/check/',
} as const;

export class PuzzlesService extends BaseService {
  getCategories(params: IApiPuzzlesGetCategoriesRequestParams) {
    const { limit = 15, offset = 0, with_count = true } = params;

    const url = combineURLParamsByMap(SERVICE_URLS.GET_PUZZLESS_CATEGORIES, {
      limit,
      offset,
      with_count,
    });

    return this.ajax.get<IApiPuzzlesGetCategoriesResponseData>(url);
  }

  getPuzzleByCategory(category: string, jwt?: string) {
    const url = SERVICE_URLS.GET_PUZZLE_BY_CATEGORY.replace(
      ':category',
      category
    );

    return this.ajax.get<IApiPuzzlesGetPuzzleByCategoryResponseData>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }

  getRatedPuzzle(jwt?: string) {
    const url = SERVICE_URLS.GET_RATED_PUZZLE;

    return this.ajax.get<IApiPuzzlesGetPuzzleByCategoryResponseData>(
      url,
      createAuthorizationHeaders(jwt)
    );
  }

  checkPuzzle(
    id: string,
    data: IApiPuzzlesCheckPuzzleRequestData,
    jwt?: string
  ) {
    const url = SERVICE_URLS.CHECK_PUZZLE.replace(':id', id);

    return this.ajax.post<IApiPuzzlesCheckPuzzleResponseData>(url, {
      body: data,
      ...createAuthorizationHeaders(jwt),
    });
  }
}

export const puzzlesService = new PuzzlesService({
  instance: httpInstance,
});
