"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
const utils_1 = require("./utils");
const Skeleton_1 = require("../../general/Skeleton");
const HomeWelcome = (props) => {
    const { title, description, isLoading } = props;
    const prepared = (0, react_1.useMemo)(() => (0, utils_1.getPreparedItems)(description), [description]);
    if (isLoading) {
        return ((0, jsx_runtime_1.jsx)(style_1.$HomeWelcome, { "data-component": "HomeWelcome", children: (0, jsx_runtime_1.jsx)(style_1.$HomeWelcomeInner, { children: (0, jsx_runtime_1.jsx)(Skeleton_1.Skeleton, { height: '474px' }) }) }));
    }
    return ((0, jsx_runtime_1.jsx)(style_1.$HomeWelcome, { "data-component": "HomeWelcome", children: (0, jsx_runtime_1.jsxs)(style_1.$HomeWelcomeInner, { children: [(0, jsx_runtime_1.jsx)(style_1.$HomeWelcomeTitle, { children: title }), (0, jsx_runtime_1.jsx)(style_1.$HomeWelcomeDescription, { children: prepared.map((block) => ((0, jsx_runtime_1.jsx)(style_1.$HomeWelcomeDescriptionBlock, { children: block.items.map((item) => ((0, jsx_runtime_1.jsx)(style_1.$HomeWelcomeDescriptionItem, { children: item.text }, item.id))) }, block.id))) })] }) }));
};
exports.default = HomeWelcome;
