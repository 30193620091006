import { FC, PropsWithChildren, memo } from 'react';

import HomePuzzles from '@chessarena/components/lib/home/HomePuzzles';

import { PuzzlesSectionContextProvider } from './context';

import { usePuzzlesData } from './_usePuzzlesData.hook';

const PuzzlesSectionInner: FC<PropsWithChildren> = memo(
  function PuzzlesSectionInner() {
    const puzzlesData = usePuzzlesData();

    return <HomePuzzles {...puzzlesData} />;
  }
);

export const PuzzlesSection: FC<PropsWithChildren> = memo(
  function GamesSection() {
    return (
      <PuzzlesSectionContextProvider>
        <PuzzlesSectionInner />
      </PuzzlesSectionContextProvider>
    );
  }
);
