import { Dispatch } from 'react';

import {
  ePuzzlesSectionReducerActionTypes,
  IPuzzlesSectionContextStateData,
  IPuzzlesSectionReducerAction,
} from './_types';

export const useActions = (
  dispatch: Dispatch<IPuzzlesSectionReducerAction>
) => {
  return {
    setData: (data: IPuzzlesSectionContextStateData): void => {
      return dispatch({
        type: ePuzzlesSectionReducerActionTypes.SET_DATA,
        payload: data,
      });
    },

    setLoading: (value: boolean): void => {
      return dispatch({
        type: ePuzzlesSectionReducerActionTypes.SET_LOADING,
        payload: value,
      });
    },

    setError: (error: string): void => {
      return dispatch({
        type: ePuzzlesSectionReducerActionTypes.SET_ERROR,
        payload: error,
      });
    },
  };
};
