import { useCallback } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { HomeMasterclassesItemActionClickParams } from '@chessarena/components/lib/home/HomeMasterclassesItem/types';
import { RenderImageParams } from '@chessarena/components/lib/utils/renderImageTypes';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { ePopupPaths, routesConstants } from '@constants';
import { openPopup } from '@utils/_router';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { useMasterclassSectionContext } from '../context';

import { useMasterclassItems } from './useMasterclassItems.hook';

export const useMasterclassData = () => {
  const { masterclassItems, isLoading: isMasterclassItemsLoading } =
    useMasterclassItems();
  const {
    state: { masterclassData },
  } = useMasterclassSectionContext();

  const isSectionLoading = isMasterclassItemsLoading;

  const router = useRouter();

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return (
      <Link
        href={link}
        onClick={() => gtmPush({ event: GTM_EVENTS.MASTERCLASS_LESSON_CLICK })}
      >
        {children}
      </Link>
    );
  };

  const renderImage = useCallback(
    ({ src, alt, style }: RenderImageParams) => (
      <img
        src={src}
        alt={alt ?? ''}
        style={{ width: '100%', height: '100%', ...style }}
      />
    ),
    []
  );

  const onActionClick = useCallback<
    (params: HomeMasterclassesItemActionClickParams) => void
  >(
    (params) => {
      gtmPush({ event: GTM_EVENTS.MASTERCLASS_LESSON_CLICK });
      switch (params.id) {
        case 'upgrade':
          openPopup(
            `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.MASTERCLASS}`
          );
          return;
        case 'anon-dive-into':
          router.push(routesConstants.MASTERCLASSES);
          return;
        case 'subscribed-dive-into':
          const bannerItem = masterclassItems?.[0];

          if (!bannerItem) return;
          const pushToRoute = bannerItem.link ?? routesConstants.MASTERCLASSES;
          router.push(pushToRoute);

          return;
      }
    },
    [masterclassItems, router]
  );

  return {
    masterclassesItems: masterclassItems,
    masterclassesQuote: masterclassData?.quote,
    cardData: masterclassData?.card,
    renderImage,
    renderLink,
    onActionClick,

    isLoading: isSectionLoading,
  };
};
