import { useMemo } from 'react';

import { useHomeContext } from '@pages/home/context';

export const usePuzzlesDataQuote = () => {
  const { data } = useHomeContext();

  return useMemo(() => {
    return {
      id: 'quote',
      text: data?.sections?.puzzles.items.quote.title || '',
    };
  }, [data]);
};
