import { useCallback, useMemo } from 'react';

import { useApplicationContext } from '@application';
import { useQuickGameSectionContext } from './contexts/quick-game';

import { getHomeTimeControls } from '@pages/home/sections/QuickGameSection/utils/_getHomeTimeControls';
import { useQuickGameMessageData } from '@pages/home/sections/QuickGameSection/_useQuickGameMessageData.hook';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { IShortChallengeRequestParams } from '@types';
import { useHomeContext } from '@pages/home/context';
import { ISystemPopups } from 'shared/types';

export const useQuickGameTimeControlsData = (
  popups: Pick<ISystemPopups, 'alert'>
) => {
  const {
    state: {
      data: { activeTabId, gameIsStarting, mounted },
    },
    computed: { tabs },
  } = useQuickGameSectionContext();
  const {
    state: {
      shortChallenges,
      shortChallengesRequest,
      shortChallengeRequests,
      cancelShortChallengeRequests,
    },
    actions: { createShortChallenge, cancelShortChallenge },
  } = useLobbyContext();
  const { data } = useHomeContext();

  const { localization } = useApplicationContext();

  const message = useQuickGameMessageData();

  const items = useMemo(
    () => getHomeTimeControls(tabs, localization, activeTabId, data),
    [tabs, activeTabId, localization, data]
  );

  const onClick = useCallback(
    async (value: string) => {
      if (message) return;
      if (gameIsStarting) return;

      const timeControl = tabs
        .find((item) => item.id === activeTabId)
        ?.items.find((element) => element.id === value)?.timeControl;

      if (timeControl && activeTabId) {
        const sending = shortChallengeRequests.find(
          (item) =>
            item.ratingType === activeTabId &&
            item.timeControlId === timeControl?.id
        );

        if (sending) return;

        const params: IShortChallengeRequestParams = {
          ratingType: activeTabId,
          timeControlId: timeControl.id,
        };

        await createShortChallenge({
          params,
          popups,
        });
      }
    },
    [
      message,
      gameIsStarting,
      tabs,
      activeTabId,
      shortChallengeRequests,
      createShortChallenge,
      popups,
    ]
  );

  const onCancel = useCallback(
    async (value: string) => {
      const item = shortChallenges.find(
        (item) =>
          item.rating_type === activeTabId && item.time_control.id === +value
      );

      if (cancelShortChallengeRequests.includes(item?.id as number)) return;

      if (item?.id) {
        await cancelShortChallenge({
          challengeId: item.id,
        });
      }
    },
    [
      shortChallenges,
      cancelShortChallengeRequests,
      activeTabId,
      cancelShortChallenge,
    ]
  );

  return {
    value: activeTabId,
    loading: !mounted || shortChallengesRequest,
    message,
    items,
    onClick,
    onCancel,
  };
};
