import { useWelcomeSectionContext } from '../context';

export const useWelcomeData = () => {
  const {
    state: { welcome },
  } = useWelcomeSectionContext();

  return {
    title: welcome?.title,
    description: welcome?.description,
  };
};
