import { FC, PropsWithChildren, useEffect, useMemo, useReducer } from 'react';

import { useHomeContext } from '@pages/home/context';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';

import { useActions } from './_actions';
import { QuickGameSectionContext } from './_context';
import { createInitState, QuickGameSectionReducer } from './_reducer';
import { IQuickGameSectionContextProps } from './_types';
import { getComputedTabs } from '../../utils/_getComputedTabs';
import { useRouteChangeHook } from './hooks/_useRouteChange.hook';
import { useAuthStore } from '@store/storeshed';
import { getCookie } from '@utils/_cookies';
import { lsKeys } from '@types';

export const QuickGameSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();
  const {
    state: { shortChallenges, shortChallengeRequests },
    actions: { getShortChallenges },
  } = useLobbyContext();

  const userUid = useAuthStore('uid');

  const [state, dispatch] = useReducer(
    QuickGameSectionReducer,
    createInitState(data)
  );

  const {
    setMounted,
    setActiveTabId,
    setTabs,
    setGameIsStarting,
    setInviteSettingsOpened,
  } = useActions(dispatch);

  useRouteChangeHook(setGameIsStarting);

  const tabs = useMemo(
    () =>
      getComputedTabs(state.data.tabs, shortChallenges, shortChallengeRequests),
    [state, shortChallenges, shortChallengeRequests]
  );

  useEffect(() => {
    const token = getCookie(lsKeys.JWT);
    if (userUid) {
      setMounted(true);
      getShortChallenges(userUid, token);
    }

    return () => {
      setMounted(false);
    };
  }, [userUid]);

  const value = useMemo<IQuickGameSectionContextProps>(() => {
    return {
      state,
      computed: { tabs },
      actions: { setActiveTabId, setTabs, setInviteSettingsOpened },
    };
  }, [state, tabs, setActiveTabId, setTabs, setInviteSettingsOpened]);

  return (
    <QuickGameSectionContext.Provider value={value}>
      {children}
    </QuickGameSectionContext.Provider>
  );
};
