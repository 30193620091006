import HomePageBottom from '@chessarena/components/lib/home/HomePageBottom';
import { FC, memo } from 'react';
import { useBottomData } from '../hooks/useBottomData.hook';

export const HomePageBottomFragment: FC = memo(
  function HomePageBottomFragment() {
    const { renderLink, pageBottomItems, onItemClick, isMounted } =
      useBottomData();

    if (!isMounted) return null;

    return (
      <HomePageBottom
        items={pageBottomItems}
        renderLink={renderLink}
        onItemClick={onItemClick}
      />
    );
  }
);
