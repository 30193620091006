"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreparedItems = void 0;
const getItems = (text) => {
    return text.split('\n').map((item, i) => {
        return {
            id: i,
            text: item,
        };
    });
};
const getPreparedItems = (data) => {
    if (!data)
        return [];
    return data === null || data === void 0 ? void 0 : data.map((item, i) => {
        return {
            id: i,
            items: getItems(item),
        };
    });
};
exports.getPreparedItems = getPreparedItems;
