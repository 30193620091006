import { useMemo, useState, useCallback } from 'react';
import { useRouter } from 'next/router';

import { GameSettingsTab } from '@chessarena/components/lib/home/Home/types';
import { useQuickGameSectionContext } from './contexts/quick-game';

import { useHomeContext } from '@pages/home/context';
import { useApplicationContext } from '@application';
import { InputEventParams } from '@chessarena/components/lib/inputs/types';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { getTimeControlTimeInit } from '@pages/home/sections/QuickGameSection/utils/_getTimeControlTimeInit';

import { eChessColor } from 'shared/types';

import { playerSideTabs } from './_constants';
import { getTimeControlTypes } from '@utils/timeControls/_getTimeControlTypes';
import { getTimeControlTimes } from '@utils/timeControls/_getTimeControlTimes';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export const useQuickGameInviteSettingsData = () => {
  const {
    state: {
      data: { inviteSettingsOpened: opened },
    },
    actions: { setInviteSettingsOpened },
  } = useQuickGameSectionContext();
  const {
    state: { inviteChallengeRequest },
    actions: { createInviteChallenge },
  } = useLobbyContext();
  const { data } = useHomeContext();
  const { localization: l } = useApplicationContext();
  const router = useRouter();

  const [playerSide, setPlayerSide] = useState<GameSettingsTab>('white');
  const [timeControlType, setTimeControlType] = useState<string>('blitz');
  const [timeControlTime, setTimeControlTime] = useState<string>(
    getTimeControlTimeInit(data, timeControlType)
  );

  const timeControlTypes = useMemo(
    () => getTimeControlTypes(l, data?.game_settings_time_control_order),
    [data?.game_settings_time_control_order, l]
  );
  const timeControlTimes = useMemo(
    () => getTimeControlTimes(l, data?.time_controls, timeControlType),
    [data?.time_controls, l, timeControlType]
  );

  const captions = useMemo(
    () => ({
      title: data?.sections.quick_game.invite.popup.title || '',
      description: data?.sections.quick_game.invite.popup.description || '',
      playerSideLabel:
        data?.sections.quick_game.invite.popup.fields.player_side || '',
      timeControlLabel:
        data?.sections.quick_game.invite.popup.fields.game_settings || '',
    }),
    [data]
  );

  const onSaveClick = useCallback(async () => {
    const desiredColor = playerSide === 'random' ? null : playerSide;
    const params = {
      desiredColor: desiredColor as eChessColor,
      timeControlId: +timeControlTime,
    };
    gtmPush({ event: GTM_EVENTS.CREATE_GAME_WITH_FRIEND_CLICK });
    await createInviteChallenge({
      params,
      router,
      isFriendGame: true,
    });
  }, [createInviteChallenge, playerSide, timeControlTime, router]);

  const buttonProps = useMemo(
    () => ({
      title: data?.sections.quick_game.invite.popup.action ?? '',
      loading: inviteChallengeRequest,
      onClick: onSaveClick,
    }),
    [data, onSaveClick, inviteChallengeRequest]
  );

  const onPlayerSideClick = useCallback((tab: GameSettingsTab) => {
    setPlayerSide(tab);
  }, []);

  const onTimeControlTypeClick = useCallback(
    ({ value }: InputEventParams) => {
      setTimeControlType(value as string);
      setTimeControlTime(getTimeControlTimeInit(data, value as string));
    },
    [data]
  );

  const onTimeControlTimeClick = useCallback(({ value }: InputEventParams) => {
    setTimeControlTime(value as string);
  }, []);

  const onClose = useCallback(() => {
    setInviteSettingsOpened(false);
  }, [setInviteSettingsOpened]);

  return {
    opened,
    popupProps: {
      ...captions,
      playerSide,
      playerSideTabs,
      onPlayerSideClick,

      timeControlType,
      timeControlTypes,
      onTimeControlTypeClick,

      timeControlTime,
      timeControlTimes,
      onTimeControlTimeClick,

      buttonProps,
      onClose,
    },
  };
};
