import { useCallback } from 'react';

import { IRequestData } from '@types';
import { useApplicationContext } from '@application';
import { helpersService } from '@services/_helpers.service';

import { adaptBestPlayerResponse, adaptStatsResponse } from './_adapters';
import {
  eHomePageHeaderContextActionType,
  TBestPlayerData,
  THomePageHeaderContextDispatch,
  TStats,
} from './_types';

export const useHeaderSectionContextActions = ({
  dispatch,
}: {
  // state: IHomePageHeaderContextState;
  dispatch: THomePageHeaderContextDispatch;
}) => {
  const { localization: l } = useApplicationContext();

  const setBestPlayerDataRequest = useCallback(
    (value: Partial<IRequestData<TBestPlayerData>>) => {
      dispatch({
        type: eHomePageHeaderContextActionType.SET_BEST_PLAYER_REQUEST,
        payload: value,
      });
    },
    [dispatch]
  );

  const fetchBestPlayerData = useCallback(async () => {
    setBestPlayerDataRequest({
      status: 'request',
    });

    try {
      const { data, ok } = await helpersService.getBestPlayer();

      if (ok) {
        const adaptedData = adaptBestPlayerResponse(data, l);

        setBestPlayerDataRequest({
          data: adaptedData,
          status: 'done',
        });
      }
    } catch (err) {
      setBestPlayerDataRequest({
        error: err as string,
        status: 'error',
      });
    }
  }, [l, setBestPlayerDataRequest]);

  const setStatsDataRequest = useCallback(
    (value: Partial<IRequestData<TStats>>) => {
      dispatch({
        type: eHomePageHeaderContextActionType.SET_STATS_REQUEST,
        payload: value,
      });
    },
    [dispatch]
  );

  const fetchStatsData = useCallback(async () => {
    setStatsDataRequest({
      status: 'request',
    });

    try {
      const { data, ok } = await helpersService.getPlayersCount();

      if (ok) {
        const adaptedData = adaptStatsResponse(data, l);

        setStatsDataRequest({
          data: adaptedData,
          status: 'done',
        });
      }
    } catch (err) {
      setStatsDataRequest({
        error: err as string,
        status: 'error',
      });
    }
  }, [l, setStatsDataRequest]);

  return {
    fetchBestPlayerData,
    fetchStatsData,
  };
};
