import { FC, memo } from 'react';

import { MasterclassSectionContextProvider } from './context';
import { HomeMasterclassFragment } from './fragments/HomeMasterclassFragment';

export const MasterclassSection: FC = memo(function MasterclassSection() {
  return (
    <MasterclassSectionContextProvider>
      <HomeMasterclassFragment />
    </MasterclassSectionContextProvider>
  );
});
