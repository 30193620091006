import {
  IPlayerResponse,
  ITopPlayerResponse,
  TTopPlayersByRatingResponse,
} from '@types';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export class RatingsService extends BaseService {
  /**
   * Получить список топ 5 игроков для указанного рейтинга по трем типам тайм контроля
   */
  getTopPlayersByRatingType(ratingType: string, playersCount: number) {
    return this.ajax.get<TTopPlayersByRatingResponse>(
      `online/ratings/?rating_type=${ratingType}&offset=0&limit=${playersCount}`
    );
  }

  /**
   * Получить игроков в онлайне, которые сейчас находятся в игре
   *
   */
  getNowPlayingPlayers() {
    return this.ajax.get<ITopPlayerResponse[]>(`online/ratings/now-playing/`);
  }

  /**
   * Получить список лучших игроков
   */
  getBestOnlinePlayers() {
    return this.ajax.get<ITopPlayerResponse[]>(
      `online/ratings/best-online-players/`
    );
  }

  /**
   * Получить список лучших игроков
   */
  getMostGamesTodayPlayers() {
    return this.ajax.get<ITopPlayerResponse[]>(
      `online/ratings/most-games-today/`
    );
  }

  /**
   * Получить игроков с максимальным изменением рейтинга за сегодня
   *
   */
  getTopTodayPlayers() {
    return this.ajax.get<ITopPlayerResponse[]>(`online/ratings/top-today/`);
  }

  /**
   * Получить результаты поиска по игрокам
   */
  getSearchResults(search: string) {
    return this.ajax.get<IPlayerResponse[]>(
      `online/players/autocomplete/?letters=${encodeURIComponent(search)}`
    );
  }

  /**
   * Получить данные о месте запрашиваемого игрока и соседних игроков
   */
  getPlayerPlaceWithNeighbors(ratingType: string, playerId: number) {
    return this.ajax.get<TTopPlayersByRatingResponse>(
      `online/ratings/${playerId}/player-place/?rating_type=${ratingType}`
    );
  }
}

export const ratingsService = new RatingsService({ instance: httpInstance });
