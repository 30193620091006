import { FC, PropsWithChildren, useMemo } from 'react';

import { HomeContext } from './_context';
import { IHomeContextProviderProps, IHomeContextValue } from './_types';

export const HomeContextProvider: FC<
  PropsWithChildren<IHomeContextProviderProps>
> = ({ children, data }) => {
  const value = useMemo<IHomeContextValue>(() => {
    return { data };
  }, [data]);

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};
