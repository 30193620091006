import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { IHomePageData } from '@pages/home/_types';
import { useHomeContext } from '@pages/home/context';
import { adaptBottomItemsResponse } from './_adapters';
import { BottomSectionContext } from './_context';
import { IBottomSectionContext, IHomePageBottomContextState } from './_types';

const initialState: IHomePageBottomContextState = {
  items: undefined,
};

const createInitialState = (
  homeContextData?: IHomePageData
): IHomePageBottomContextState => {
  const items = adaptBottomItemsResponse(homeContextData?.sections.bottom);

  return {
    ...initialState,
    items,
  };
};

export const BottomSectionContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useHomeContext();

  const [state] = useState(createInitialState(data));

  const value = useMemo<IBottomSectionContext>(
    () => ({
      state,
    }),
    [state]
  );

  return (
    <BottomSectionContext.Provider value={value}>
      {children}
    </BottomSectionContext.Provider>
  );
};
