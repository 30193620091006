"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreparedItems = void 0;
const getPreparedItems = (loading, rated, rating, categories, quote, enjoy) => {
    var _a, _b;
    const result = [];
    if (loading) {
        Array.from({ length: 7 }, (_, i) => i).forEach((item) => {
            result.push({ id: item, loading });
        });
    }
    else {
        result.push({ id: rated.id, rated });
        result.push({ id: rating === null || rating === void 0 ? void 0 : rating.id, rating });
        (_a = categories === null || categories === void 0 ? void 0 : categories.items) === null || _a === void 0 ? void 0 : _a.slice(0, 2).forEach((item) => {
            result.push({ id: item.id, category: item });
        });
        result.push({ id: quote.id, quote });
        (_b = categories === null || categories === void 0 ? void 0 : categories.items) === null || _b === void 0 ? void 0 : _b.slice(2).forEach((item) => {
            result.push({ id: item.id, category: item });
        });
        result.push({ id: enjoy.id, enjoy });
    }
    return result;
};
exports.getPreparedItems = getPreparedItems;
