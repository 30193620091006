import { IGameMove } from 'shared/types';

export const getStreamBoardMovesFromMoves = (moves?: IGameMove[] | null) => {
  return (
    moves?.map((item) => ({
      fen: item.fen,
      lan: item.long_san,
      moveNumber: item.move_number,
    })) || []
  );
};
