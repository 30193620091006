import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';

import { useQuickGameInfoData } from './_useQuickGameInfoData.hook';
import { useQuickGameTabsData } from './_useQuickGameTabsData.hook';
import { useQuickGameTimeControlsData } from './_useQuickGameTimeControlsData.hook';
import { useQuickGameInviteData } from './_useQuickGameInviteData.hook';
import { useQuickGameInviteSettingsData } from './_useQuickGameInviteSettingsData.hook';
import { useQuickGameTopGameData } from './_useQuickGameTopGameData.hook';

export const useQuickGameData = () => {
  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const info = useQuickGameInfoData();
  const tabs = useQuickGameTabsData();
  const timeControls = useQuickGameTimeControlsData({
    alert: showAlertPopup,
  });
  const invite = useQuickGameInviteData();
  const inviteSettings = useQuickGameInviteSettingsData();
  const topGame = useQuickGameTopGameData();

  const alert = {
    isAlertPopupShown,
    alertPopupRef,
    onClose,
    alertText,
  };

  return { alert, info, invite, tabs, timeControls, topGame, inviteSettings };
};
