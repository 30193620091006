import { useMemo } from 'react';

import { routesConstants } from '@constants';
import { useHomeContext } from '@pages/home/context';

export const usePuzzlesDataCardData = () => {
  const { data } = useHomeContext();

  return useMemo(() => {
    return {
      description: data?.sections?.puzzles.description || '',
      viewAll: {
        text: data?.sections?.puzzles.view_all || '',
        href: routesConstants.PUZZLES,
      },
      helpItems: data?.sections?.puzzles.help || [],
    };
  }, [data]);
};
