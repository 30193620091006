import { FC, memo } from 'react';

import { CGStreamBoard } from 'chessgun/react';

import { useTopGameBoardData } from './_useTopGameBoardData.hook';

export const TopGameBoard: FC<{ boardSize: number }> = memo(
  function TopGameBoard({ boardSize }: { boardSize: number }) {
    const { visible, ...boardData } = useTopGameBoardData(boardSize);

    if (!visible) return null;

    return <CGStreamBoard {...boardData} showTitles />;
  }
);
