import { IQuickGameTab } from '@pages/home/sections/QuickGameSection/_types';
import { IChallengeItem } from 'shared/types';
import { IShortChallengeRequestParams } from '@types';

export const getComputedTabs = (
  tabs: IQuickGameTab[],
  challenges?: IChallengeItem[],
  challengeRequests?: IShortChallengeRequestParams[]
): IQuickGameTab[] => {
  return tabs.map((item) => ({
    ...item,
    items: item.items?.map((element) => {
      const hasChallenge = !!challenges?.find(
        (ch) => ch.rating_type === item.id && ch.time_control.id === +element.id
      );
      const hasChallengeRequest = !!challengeRequests?.find(
        (ch) => ch.ratingType === item.id && ch.timeControlId === +element.id
      );
      return {
        ...element,
        active: hasChallenge || hasChallengeRequest,
      };
    }),
  }));
};
