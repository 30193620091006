import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';

import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';

import { ePopupPaths } from '@constants';
import { openPopup } from '@utils/_router';
import { UserStatus, useUserStatus } from '@utils/hooks/_useUserSatatus';
import { ePaywallPopupType } from '@components/molecules/popups/PaywallPopup/_types';
import { useUnsubscribedPuzzles } from '@utils/hooks/_useUnsubscribedPuzzles.hook';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

import { usePuzzlesDataCardData } from './_usePuzzlesDataCardData.hook';
import { usePuzzlesDataTitle } from './_usePuzzlesDataTitle.hook';
import { usePuzzlesDataRated } from './_usePuzzlesDataRated.hook';
import { usePuzzlesDataQuote } from './_usePuzzlesDataQuote.hook';
import { usePuzzlesDataEnjoy } from './_usePuzzlesDataEnjoy.hook';
import { usePuzzlesSectionContext } from './context';
import { usePuzzlesDataRating } from './_usePuzzlesDataRating.hook';
import { usePuzzlesDataCategories } from './_usePuzzlesDataCategories.hook';

export const usePuzzlesData = () => {
  const {
    state: { status },
  } = usePuzzlesSectionContext();

  const userStatus = useUserStatus();
  const { limitExhausted } = useUnsubscribedPuzzles();

  const loading = useMemo(() => status === 'request', [status]);

  const renderLink = ({ link, children }: RenderLinkParams) => {
    return <Link href={link}>{children}</Link>;
  };

  const withLink = useMemo(
    () =>
      userStatus === UserStatus.SUBSCRIBED ||
      (userStatus === UserStatus.AUTHORIZED && !limitExhausted),
    [limitExhausted, userStatus]
  );

  const handleClick = useCallback(
    (id?: string) => {
      if (id === 'reated') gtmPush({ event: GTM_EVENTS.RATED_PUZZLES_CLICK });
      else if (id !== 'rating' && id !== 'enjoy')
        gtmPush({ event: GTM_EVENTS.UNRATED_PUZZLES_CLICK });

      if (userStatus === UserStatus.AUTHORIZED) {
        openPopup(
          `?popup=${ePopupPaths.PAYWALL}&type=${ePaywallPopupType.PUZZLES}`
        );
      } else {
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
      }
    },
    [userStatus]
  );

  const handleGtmClick = useCallback((id?: string) => {
    if (id === 'reated') gtmPush({ event: GTM_EVENTS.RATED_PUZZLES_CLICK });
    else if (id !== 'rating' && id !== 'enjoy')
      gtmPush({ event: GTM_EVENTS.UNRATED_PUZZLES_CLICK });
  }, []);

  const handleItemClick = useMemo(
    () =>
      userStatus === UserStatus.UNAUTHORIZED ||
      (userStatus === UserStatus.AUTHORIZED && limitExhausted)
        ? handleClick
        : handleGtmClick,
    [handleClick, handleGtmClick, limitExhausted, userStatus]
  );

  const title = usePuzzlesDataTitle();
  const cardData = usePuzzlesDataCardData();
  const rated = usePuzzlesDataRated(withLink, handleItemClick);
  const rating = usePuzzlesDataRating(withLink);
  const categories = usePuzzlesDataCategories(withLink, handleItemClick);
  const quote = usePuzzlesDataQuote();
  const enjoy = usePuzzlesDataEnjoy(withLink);

  return {
    title,
    cardData,
    loading,
    rated,
    rating,
    categories,
    quote,
    enjoy,
    renderLink,
    onItemClick: handleItemClick,
  };
};
